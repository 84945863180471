import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {environment} from '@env/environment';

declare let gtag: any;
declare let fbq: any;

declare global {
  interface Window {
    dataLayer: any[];
  }
}
@Injectable({
  providedIn: 'root'
})
export class AnalyticsServiceTsService {
  private renderer: Renderer2;
  gtag_id = environment.gtag_id;
  piksel_id = environment.piksel_id;
  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private _document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initializeAllAnalyticsServices(): void {
    this.addAddressScript();
    this.addDataLayerScript();
    this.loadFacebookPixel();
    this.addGoogleTagManagerScript();
    this.addGoogleTagManagerNoScript();
  }

  addAddressScript() {
    const addressScript = this.renderer.createElement('script');
    addressScript.setAttribute('async', '');

    addressScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.gtag_id}`;

    this.renderer.appendChild(this._document.head, addressScript);
  }

  addDataLayerScript() {
    const layerScript = this.renderer.createElement('script');

    const text = this.renderer.createText(`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', '${this.gtag_id}');
    `);

    this.renderer.appendChild(layerScript, text);
    this.renderer.appendChild(this._document.head, layerScript);
  }

  loadFacebookPixel() {
    const fbScript = document.createElement('script');
    fbScript.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    `;
    document.head.appendChild(fbScript);

    fbScript.onload = () => {
      fbq('init', this.piksel_id);
      fbq('track', 'PageView');
    };
  }

  addGoogleTagManagerScript() {
    const gtmScript = this.renderer.createElement('script');

    const text = this.renderer.createText(`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${environment.gtag_menager}');
    `);

    this.renderer.appendChild(gtmScript, text);
    this.renderer.appendChild(this._document.head, gtmScript);
  }

  addGoogleTagManagerNoScript() {
    const gtmNoScript = this.renderer.createElement('noscript');

    const iframe = this.renderer.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${environment.gtag_menager}`;
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    iframe.height = '0';
    iframe.width = '0';

    this.renderer.appendChild(gtmNoScript, iframe);

    // Check if body has child nodes and insert before the first child
    if (this._document.body.hasChildNodes()) {
      this.renderer.insertBefore(this._document.body, gtmNoScript, this._document.body.firstChild);
    } else {
      // If no child nodes, just append to body
      this.renderer.appendChild(this._document.body, gtmNoScript);
    }
  }
}
