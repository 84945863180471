import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {I18nService} from '@core/service/i18next.service';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.sass']
})
export class DragAndDropComponent implements OnInit, OnChanges {
  @ViewChild('previewContainer', { static: false }) previewContainer: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() isFileChosenCorrectly: boolean;
  @Input() base64Input: string;
  @Input() allowedFileTypes: string[] = [];
  @Output() emitFile = new EventEmitter<any>();
  @Output() emitFileNotConverted = new EventEmitter<any>();
  public files: any = [];
  isFileChosen: boolean = false;
  allowedFileTypesString: string;
  isImage: boolean;

  constructor(public i18n: I18nService, private _snackBar: MatSnackBar, public dialog: MatDialog){}

  ngOnInit() {
    if (this.base64Input) {
      this.base64ToFile(this.base64Input).then(file => {
        this.onFileChange([file], true);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['base64Input']?.currentValue && !this.isFileChosen) {
      this.base64ToFile(changes['base64Input']?.currentValue).then(file => {
        this.onFileChange([file], true);
      });
    }

    if (changes['allowedFileTypes']?.currentValue) {
      this.setAllowedFileTypes();
    }
  }

  async onFileChange(pFileList: File[], fromLoad?: boolean): Promise<void> {
    for (let i = 0; i < pFileList.length; i++) {
      const file = pFileList[i];

      const base64 = await this.fileToBase64(file);
      this.emitFileNotConverted.emit(file);
      if (base64) {
        this.emitFile.emit(base64);
        this.files.push(file);

        while (this.previewContainer.nativeElement.firstChild) {
          this.previewContainer.nativeElement.firstChild.remove();
        }

        if (file.type.startsWith('image/')) {
          const url = window.URL.createObjectURL(file);
          const image = new Image();
          image.src = url;
          image.classList.add('imgChosen');
          image.style.width = "auto";
          image.style.height = "120px";
          image.style.maxWidth = "280px";
          image.style.objectFit="contain"
          this.previewContainer.nativeElement.appendChild(image);
        } else {
          const fileName = document.createTextNode(file.name);
          this.previewContainer.nativeElement.appendChild(fileName);
        }

        this.isFileChosen = true;
        if (!fromLoad) {
          this._snackBar.open(this.i18n.t('dialogs.fileUpload.successfullyUploaded'), this.i18n.t('dialogs.fileUpload.close'), {
            duration: 2000,
          });
        }
      }
    }
  }

  async base64ToFile(base64: string): Promise<File> {
    const response = await fetch(base64);
    const blob = await response.blob();
    const [, fileType] = /data:([a-zA-Z0-9]*\/[a-zA-Z0-9-.+]*);base64,([^"]*)/.exec(base64) || [];
    return new File([blob], `file.${fileType.split('/')[1]}`, {type: fileType});
  }
  chooseDifferentFile(): void {
    this.isFileChosen = false;
    this.emitFile.emit('');

    while (this.previewContainer.nativeElement.firstChild) {
      this.previewContainer.nativeElement.firstChild.remove();
    }
  }

  private async fileToBase64(file: File): Promise<string> {
    if (!this.allowedFileTypes.includes(file.type)) {
      this._snackBar.open(this.i18n.t('dialogs.fileUpload.notCorrectFileType'), this.i18n.t('dialogs.fileUpload.close'), {
        duration: 7000,
      });
      this.fileInput.nativeElement.value = '';
      throw new Error('Not correct file type');
    }
    const isImage = file.type.startsWith('image/');
    const maxSize = isImage ? 200 * 1024 : 1024 * 1024;
    if (file.size > maxSize) {
      this._snackBar.open(
          isImage ? this.i18n.t('dialogs.fileUpload.fileSizeIsToBig200kB') : this.i18n.t('dialogs.fileUpload.fileSizeIsToBig1MB'),
          this.i18n.t('dialogs.fileUpload.close'),
          {duration: 7000});
      this.fileInput.nativeElement.value = '';
      throw new Error('File size is to big!');
    }

    const blob = await this.readFileAsBlob(file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        const mimeType = file.type;
        const dataUrl = base64.replace(/^data:.+;base64,/, `data:${mimeType};base64,`);
        resolve(dataUrl);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  async readFileAsBlob(file: File): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(new Blob([reader.result as ArrayBuffer]));
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }

  private setAllowedFileTypes(): void {
    const mimeTypeToFileExtension: { [key: string]: string } = {
      'application/pdf': 'pdf',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'image/jpg': 'jpg',
      'image/jpeg': 'jpeg',
      'image/png': 'png',
      'image/svg+xml': 'svg'
    };

    let extensions = this.allowedFileTypes.map(mimeType => {
      if (mimeType in mimeTypeToFileExtension) {
        return mimeTypeToFileExtension[mimeType];
      }

      let extension = mimeType.split('/')[1];
      return extension.split('+')[0];
    });

    this.allowedFileTypesString = extensions.join(', ');

    this.isImage = this.allowedFileTypes.includes('image/jpg');
  }
}
