import {Injectable} from '@angular/core';

declare global {
    interface Window {
        dataLayer: any[];
    }
}

@Injectable({
    providedIn: 'root'
})
export class TagManagerService {
    constructor() {
        window.dataLayer = window.dataLayer || [];
    }

    public applicationClick(action: string) {
        window.dataLayer.push({
            event: 'application_click',
            action
        });
    }
}
