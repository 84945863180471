import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({providedIn: 'root'})
export class LayoutService {

  private spinner$ = new Subject<boolean>();

  constructor() {
  }

  spinnerStart() {
    this.spinner$.next(true);
  }

  spinnerStop() {
    this.spinner$.next(false);
  }

  spinnerObservable() {
    return this.spinner$.asObservable();
  }



}
