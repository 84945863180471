<span class="container" [class.containerMobile]="isMobileView">
  <div class="contentContainer">
    <mat-icon *ngIf="type==='toastSuccess'">done</mat-icon>
    <mat-icon *ngIf="type==='toastWarning'">warning</mat-icon>
    <mat-icon *ngIf="type==='toastDanger'">error</mat-icon>
    <span>
      {{ text }}
      <span *ngIf="linkName && linkHref && isDownload" class="link"><a href="{{ linkHref }}" aria-label="Redirect to download" download>{{ linkName }}</a></span>
      <span *ngIf="linkName && linkHref && !isDownload" class="link"><a href="{{ linkHref }}" aria-label="Redirect to link" target="_blank">{{ linkName }}</a></span>
    </span>
  </div>
  <span *ngIf="type==='toastDanger'" class="closeToast" (click)="closeToast()">{{i18n.t('dialogs.confirmDialog.confirm')}}</span>
</span>
