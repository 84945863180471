/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountCompanyService } from './services/account-company.service';
import { AccountMeService } from './services/account-me.service';
import { AccountUserService } from './services/account-user.service';
import { ApplicationService } from './services/application.service';
import { JobAnnouncementService } from './services/job-announcement.service';
import { JobPositionService } from './services/job-position.service';
import { JobSalaryUnitService } from './services/job-salary-unit.service';
import { JobSectorService } from './services/job-sector.service';
import { JobSkillsTagService } from './services/job-skills-tag.service';
import { PaymentOfferService } from './services/payment-offer.service';
import { PaymentOrderService } from './services/payment-order.service';
import { PaymentPackageService } from './services/payment-package.service';
import { PaymentPackageItemService } from './services/payment-package-item.service';
import { PaymentTransactionService } from './services/payment-transaction.service';
import { StorageService } from './services/storage.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountCompanyService,
    AccountMeService,
    AccountUserService,
    ApplicationService,
    JobAnnouncementService,
    JobPositionService,
    JobSalaryUnitService,
    JobSectorService,
    JobSkillsTagService,
    PaymentOfferService,
    PaymentOrderService,
    PaymentPackageService,
    PaymentPackageItemService,
    PaymentTransactionService,
    StorageService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
