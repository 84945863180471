import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class AppConfig {

  constructor() {
    document.title = this.appName;
  }

  appName = "BeRemotely";
  appDescription = 'Remote job offers';
  appVersion = `v. 1.0.0`;
  appCompany = `BeRemotely`;
  iconPath = 'assets/images/icons/logo-small.PNG';
}
