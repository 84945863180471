<ng-container
        *ngIf="[
        dialogTypeModel.saveConfirm,
        dialogTypeModel.deleteConfirm,
        dialogTypeModel.editConfirm].includes(data.dialogType)"
>
    <div
            class="dialogText"
            mat-dialog-content
            [class.dialogTextMobile]="isMobileView"
            [class.dangerModal]="data.dialogType === dialogTypeModel.deleteConfirm"
            [class.infoModal]="[dialogTypeModel.saveConfirm, dialogTypeModel.editConfirm].includes(data.dialogType)"
    >
        <div *ngIf="!isMobileView" class="icons">
            <mat-icon *ngIf="data?.dialogType === dialogTypeModel.deleteConfirm" class="dangerModal">warning</mat-icon>
            <mat-icon *ngIf="[dialogTypeModel.saveConfirm, dialogTypeModel.editConfirm].includes(data.dialogType)" class="infoModal">info</mat-icon>
        </div>
        <div>
            <p class="title">{{data.dialogTitle}}</p>
            <p class="textData">{{data.dialogText}}</p>
            <p *ngIf="data?.dialogText2" class="textData">
                {{data.dialogText2}}
                <span><a *ngIf="data?.link" href="{{data.link}}" target="_blank" class="link" aria-label="Redirect to dialog data">{{data.linkText}}</a></span>
            </p>

        </div>
    </div>
    <div class="dialogActions" [class.dialogActionsMobile]="isMobileView" mat-dialog-actions>
        <button
                class="cancel"
                mat-button
                (click)="cancel()"
        >
            {{data.dialogNo}}
        </button>
        <button
            class="confirm"
            mat-button
            (click)="confirm()"
        >
            {{data.dialogYes}}
        </button>
    </div>
</ng-container>
<ng-container *ngIf="[
  dialogTypeModel.confirmValue,
].includes(data.dialogType)">
    <div
            class="dialogText"
            mat-dialog-content
            [class.dangerModal]="[dialogTypeModel.deleteConfirm, dialogTypeModel.confirmValue].includes(data.dialogType)"
            [class.infoModal]="[dialogTypeModel.saveConfirm, dialogTypeModel.editConfirm].includes(data.dialogType)"
    >
        <div>
            <p class="title">{{i18n.t('dialogs.confirmDialog.type')}} {{data.valueToConfirm}}</p>
            <p class="textData">{{data.dialogText}}</p>
        </div>
    </div>
    <div class="formContainer formContainerConfirm">
        <form [formGroup]="confirmValueForm">
            <mat-form-field>
                <input
                    matInput
                    formControlName="valueToConfirm"
                    required
                    (input)="onConfirmValueChange($event)"
                >
            </mat-form-field>
        </form>
    </div>
    <div class="dialogActions" [class.dialogActionsMobile]="isMobileView" mat-dialog-actions>
        <button
            class="confirm"
            mat-button
            (click)="confirm()"
            [disabled]="!isConfirmValueCorrect"
        >
            {{data.dialogYes}}
        </button>
        <button
            mat-button
            (click)="cancel()"
        >
            {{data.dialogNo}}
        </button>
    </div>
</ng-container>
<ng-container
        *ngIf="[
        dialogTypeModel.informationModal].includes(data.dialogType)"
>
    <div
            class="dialogText informationModal"
            mat-dialog-content
            [class.informationModalMobile]="isMobileView"
    >
        <div *ngIf="!isMobileView" class="icons">
            <mat-icon class="infoModal">info</mat-icon>
        </div>
        <div>
            <p class="title">{{data.dialogTitle}}</p>
            <p class="textData">{{data.dialogText}}</p>
            <p *ngIf="data?.dialogText2" class="textData">
                {{data.dialogText2}}
                <span><a *ngIf="data?.link" href="{{data.link}}" target="_blank" class="link" aria-label="Redirect to dialog data">{{data.linkText}}</a></span>
            </p>

        </div>
    </div>
    <div class="dialogActions" [class.dialogActionsMobile]="isMobileView" mat-dialog-actions>
        <button
                class="confirm"
                mat-button
                (click)="confirm()"
        >
            {{data.dialogYes}}
        </button>
    </div>
</ng-container>
