import {Component, OnInit} from '@angular/core';
import {environment} from "@env/environment"
import {AppConfig} from "@config/index";
import i18next from 'i18next';
import {SessionService} from '@core/service';
import {AuthService} from '@auth0/auth0-angular';
import {mergeMap} from 'rxjs';
import {setCurrentUserStatus} from '@core/store/users/users.actions';
import {AccountMeService} from '@api/services/account-me.service';
import {AccountMeDto} from '@api/models/account-me-dto';
import {Store} from '@ngrx/store';
import {DialogService} from '@component/dialog/dialog.service';
import {I18nService} from '@core/service/i18next.service';
import {ToastService} from '@component/toast/toast.service';
import {AnalyticsServiceTsService} from '@core/service/analytics.service.ts.service';
import {ActivatedRoute, Data, NavigationEnd, Router} from '@angular/router';
import {MetaService} from '@core/service/meta.service';
import {filter, map} from 'rxjs/operators';

declare let gtag: any;
declare let fbq: any;

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  languageLocalStorage: string = "Language";
  isUserAuthenticated: boolean = false;
  userStatus: AccountMeDto;

  constructor(
      private appConfig: AppConfig,
      private localStorageService: SessionService,
      private authService: AuthService,
      private authMeService: AccountMeService,
      private store: Store,
      private dialog: DialogService,
      private toast: ToastService,
      public i18n: I18nService,
      private analyticsService: AnalyticsServiceTsService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private metaService: MetaService
      ) {
    this.appConfig = appConfig;
  }

  ngOnInit(): void {
    const instance = environment.instance;
    document.title = this.appConfig.appName;
    if (instance != 'prod') {
      document.title += ` - (${instance})`
    }
    this.analyticsService.initializeAllAnalyticsServices();
    this.handleAcceptCookies();
    this.setApplicationLanguage();
    this.checkIfUserIsAuthenticated();
    this.handleMetaTags();
  }

  private setApplicationLanguage() {
    const currentLanguage = this.localStorageService.get(this.languageLocalStorage) as string;

    i18next.changeLanguage(currentLanguage);
  }

  private checkIfUserIsAuthenticated() {
    this.authService.isAuthenticated$
        .subscribe(isUserAuthenticated => {
          this.isUserAuthenticated = isUserAuthenticated;
          this.checkUserStatus();
        });
  }

  private checkUserStatus() {
    if (!this.isUserAuthenticated) return;

    this.authMeService.apiAccountMeGet().subscribe(userStatus => {
      this.userStatus = userStatus;
      this.store.dispatch(setCurrentUserStatus({ userStatus: userStatus }));
    })
  }

  private handleAcceptCookies(): void {
    const cookieExists = localStorage.getItem('cookie-consent-given');

    if (!cookieExists) {
      this.toast.danger(
          this.i18n.t('dialogs.cookies.toastText1'),
          this.i18n.t('dialogs.cookies.linkText'),
          'assets/docs/PrivacyPolicy.pdf',
          true,
          'end',
          'bottom'
      )
          .subscribe(isConfirmed => {
            if (isConfirmed) {
              localStorage.setItem('cookie-consent-given', 'true');
            }
          })
    }
    // if (!cookieExists) {
    //   this.dialog.openSaveConfirm(
    //       this.i18n.t('dialogs.cookies.title'),
    //       this.i18n.t('dialogs.cookies.text1'),
    //       this.i18n.t('dialogs.cookies.text2'),
    //       'https://www.wp.pl',
    //       this.i18n.t('dialogs.cookies.linkText'),
    //   )
    //       .afterClosed()
    //       .subscribe(isConfirmed => {
    //         if (isConfirmed) {
    //           localStorage.setItem('cookie-consent-given', 'true');
    //           this.loadGoogleAnalytics();
    //           this.loadFacebookPixel();
    //         } else {
    //           localStorage.setItem('cookie-consent-given', 'false');
    //         }
    //       });
    // } else if (cookieExists === 'true') {
    //   this.loadGoogleAnalytics();
    //   this.loadFacebookPixel();
    // }
  }

  // private loadGoogleAnalytics() {
  //   if (localStorage.getItem('cookie-consent-given') === 'true') {
  //     const gtagScript = document.createElement('script');
  //     gtagScript.async = true;
  //     gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-Y01NQJ1D5T';
  //     document.head.appendChild(gtagScript);
  //     gtagScript.onload = () => {
  //       window.dataLayer = window.dataLayer || [];
  //       gtag('js', new Date());
  //       gtag('config', 'G-Y01NQJ1D5T');
  //     };
  //   }
  // }

  // private loadFacebookPixel() {
  //   // Insert the Facebook Pixel base code.
  //   const fbScript = document.createElement('script');
  //   fbScript.innerHTML = `
  //   !function(f,b,e,v,n,t,s)
  //   {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  //   n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  //   if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  //   n.queue=[];t=b.createElement(e);t.async=!0;
  //   t.src=v;s=b.getElementsByTagName(e)[0];
  //   s.parentNode.insertBefore(t,s)}(window, document,'script',
  //   'https://connect.facebook.net/en_US/fbevents.js');
  //   `;
  //   document.head.appendChild(fbScript);
  //
  //   // Initiate the Facebook Pixel and track the PageView event only if the user has accepted cookies.
  //   if (localStorage.getItem('cookie-consent-given') === 'true') {
  //     fbScript.onload = () => {
  //       fbq('init', '627432239329944');
  //       fbq('track', 'PageView');
  //     };
  //   }
  // }
  private handleMetaTags(): void {
    this.router.events
        .pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
              while (route.firstChild) {
                route = route.firstChild;
              }
              return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data),
        )
        .subscribe(({ title, description, ogTitle, ogUrl, ogImage }: Data) => {
          this.metaService.updateTitle(title);
          this.metaService.updateDescription(description);
          this.metaService.updateOgTitle(ogTitle);
          this.metaService.updateOgUrl(ogUrl);
          this.metaService.updateOgImage(ogImage);
        });
  }
}
