import {Injectable} from "@angular/core";
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {ToastComponent} from "./toast.component";
import {I18nService} from '@core/service/i18next.service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    toastOpenDuration: number = 5; //duration time in seconds when toast is open
    confirmValue = this.i18n.t('dialogs.confirmDialog.confirm');
    constructor(
        private toast: MatSnackBar,
        public i18n: I18nService
        ) { }

    success(text: string) {
        this.toast.openFromComponent(ToastComponent, {
            data: {
                text: text,
                type: 'toastSuccess'
            },
            duration: this.toastOpenDuration * 1000,
            panelClass: 'toastSuccess'
        })
    }

    warning(text: string) {
        this.toast.openFromComponent(ToastComponent, {
            data: {
                text: text,
                type: 'toastWarning'
            },
            duration: this.toastOpenDuration * 1000,
            panelClass: 'toastWarning'
        })
    }

    danger(
        text: string,
        linkName?: string,
        linkHref?: string,
        isDownload?: boolean,
        horizontalPosition?: MatSnackBarHorizontalPosition,
        verticalPosition?: MatSnackBarVerticalPosition
    ): Observable<boolean> {
        let toastComponentRef = this.toast.openFromComponent(ToastComponent, {
            data: {
                text: text,
                type: 'toastDanger',
                linkName: linkName,
                linkHref: linkHref,
                isDownload: isDownload,
            },
            panelClass: 'toastDanger',
            horizontalPosition: horizontalPosition,
            verticalPosition: verticalPosition,
        });
        // Return an Observable that emits a value when the toast is closed
        return toastComponentRef.instance.toastCloseListener$.asObservable();
    }
}
