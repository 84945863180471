import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {catchError, finalize, Observable} from 'rxjs';
import {map} from "rxjs/operators";
import {environment} from "@env/environment";
import {LayoutService} from "@shared/@service";

@Injectable()
export class HttpSpinnerInterceptor implements HttpInterceptor {

    requestCount: number = 0;
    endpoints: string[] = [];

    constructor(private layoutService: LayoutService) {
    }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isApiUrl = req.url.startsWith(environment.apiUrl);
        if (isApiUrl && !req.urlWithParams.includes('Terms=&') && !req.urlWithParams.includes('job/skills-tag')) {

            if (!this.endpoints.includes(req.url)) {
                this.requestCount++
                this.endpoints.push(req.url);
            }

            if (this.requestCount > 0) {
                this.layoutService.spinnerStart();
            }
        }
        return next.handle(req).pipe(
            map(res => {
                return res
            }),
            catchError((error: any) => {
                throw new Error(error.message);
            }),
            finalize(() => {
                this.requestCount--;
                if (this.requestCount <= 0) {
                    this.layoutService.spinnerStop();
                    this.requestCount = 0;
                    this.endpoints = [];
                }
            })
        )
    }
}
