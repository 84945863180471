import {NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {environment} from "@env/environment";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ApiModule} from "@api/api.module";
import {AuthHttpInterceptor, AuthModule} from "@auth0/auth0-angular";
import {HttpSpinnerInterceptor} from "@core/interceptors/http-spinner-interceptor.service";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from '@core/core.module';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        CoreModule,
        ApiModule.forRoot({rootUrl: environment.apiUrl}),
        AuthModule.forRoot({
            domain: environment.auth.domain,
            clientId: environment.auth.clientId,
            audience: environment.auth.audience,
            scope: environment.auth.scope,
            httpInterceptor: {
                allowedList: [
                    {
                        uri: environment.apiUrl + '/*',
                        tokenOptions: {
                            audience: environment.auth.audience,
                            scope: environment.auth.scope
                        },
                        allowAnonymous: true
                    }
                ]
            }
        }),
        NgxGoogleAnalyticsModule.forRoot('G-Y01NQJ1D5T'),
        NgxGoogleAnalyticsRouterModule.forRoot({include: ['/']}),
        BrowserAnimationsModule,
        // ServiceWorkerModule.register('ngsw-worker.js', {
        //   enabled: environment.production,
        //   // Register the ServiceWorker as soon as the application is stable
        //   // or after 30 seconds (whichever comes first).
        //   registrationStrategy: 'registerWhenStable:30000'
        // })
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpSpinnerInterceptor, multi: true},
        {provide: Window, useValue: window}
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
