import { Injectable } from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from '@ngrx/store';
import {DialogService} from '../../../@component/dialog/dialog.service';
import {ToastService} from '../../../@component/toast/toast.service';
import * as actions from './users.actions';
import {catchError, EMPTY, switchMap, tap} from 'rxjs';
import {AccountCompanyService} from '../../../@api/services/account-company.service';
import {I18nService} from '../../service/i18next.service';
import {Router} from '@angular/router';
import {setCurrentUserStatus, updateEmployerRegisterUser} from './users.actions';
import {SessionService} from '@core/service';
import {AccountUserService} from '@api/services/account-user.service';
import {AccountMeService} from '@api/services/account-me.service';

@Injectable()
export class UsersEffects {
    registerEmployerDialogConfirmTitle = 'employerRegisterForm.form.dialogs.employerConfirmCreateAccountTitle';
    registerEmployerDialogConfirmText = 'employerRegisterForm.form.dialogs.employerConfirmCreateAccountText';
    registerJobSeekerDialogConfirmTitle = 'jobSeekerRegisterForm.form.dialogs.jobSeekerConfirmCreateAccountTitle';
    registerJobSeekerDialogConfirmText = 'jobSeekerRegisterForm.form.dialogs.jobSeekerConfirmCreateAccountText';
    registerEmployerToastConfirm = 'employerRegisterForm.form.toast.employerConfirmCreateAccountToast';
    registerJobSeekerToastConfirm = 'jobSeekerRegisterForm.form.toast.jobSeekerConfirmCreateAccountToast';
    updateEmployerDialogConfirmTitle = 'employerRegisterForm.form.dialogs.employerConfirmUpdateAccountTitle';
    updateEmployerDialogConfirmText = 'employerRegisterForm.form.dialogs.employerConfirmUpdateAccountText';
    updateJobSeekerDialogConfirmTitle = 'jobSeekerRegisterForm.form.dialogs.jobSeekerConfirmUpdateAccountTitle';
    updateJobSeekerDialogConfirmText = 'jobSeekerRegisterForm.form.dialogs.jobSeekerConfirmUpdateAccountText';
    updateEmployerToastConfirm = 'employerRegisterForm.form.toast.employerConfirmUpdateAccountToast';
    updateJobSeekerToastConfirm = 'jobSeekerRegisterForm.form.toast.jobSeekerConfirmUpdateAccountToast';
    removeUserAccountConfirmTitle = 'userProfile.employer.settings.account.removeConfirmTitle';
    removeUserAccountConfirmText = 'userProfile.employer.settings.account.removeConfirmText';
    removeJobSeekerAccountConfirmTitle = 'userProfile.employer.settings.account.removeConfirmTitle';
    removeJobSeekerAccountConfirmText = 'userProfile.employer.settings.account.removeConfirmText';

    constructor(
        public i18n: I18nService,
        private actions$: Actions,
        private dialogService: DialogService,
        private store: Store,
        private toast: ToastService,
        private employerService: AccountCompanyService,
        private jobSeekerService: AccountUserService,
        private router: Router,
        private localStorageService: SessionService,
        private authMeService: AccountMeService,
    ) {}

    saveEmployerRegister$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.saveEmployerRegisterUser),
            switchMap(({employerData})=> this.dialogService
                .openSaveConfirm(this.i18n.t(this.registerEmployerDialogConfirmTitle), this.i18n.t(this.registerEmployerDialogConfirmText))
                .afterClosed()
                .pipe(
                    switchMap(confirmed => (confirmed
                        ? this.employerService.apiAccountCompanyPost$Response({body: employerData})
                            .pipe (
                                catchError(error => {
                                    return EMPTY
                                }),
                                switchMap(( {body} ) => [
                                    actions.setEmployerRegisterUser({ employerData: body }),
                                    actions.setCurrentUserStatus({ userStatus: {  isCompany: true, isUser: false} })
                                ]),
                                tap(_=> [
                                    this.toast.success(this.i18n.t(this.registerEmployerToastConfirm)),
                                    this.localStorageService.set('isUserLoggedFrom', 'employers'),
                                    this.router.navigate(['/home'])
                                ])
                            )
                        : EMPTY
                    ))
                )
            )
        )
    )

    saveJobSeekerRegister$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.saveJobSeekerRegisterUser),
            switchMap(({JobSeekerData})=> this.dialogService
                .openSaveConfirm(this.i18n.t(this.registerJobSeekerDialogConfirmTitle), this.i18n.t(this.registerJobSeekerDialogConfirmText))
                .afterClosed()
                .pipe(
                    switchMap(confirmed => (confirmed
                            ? this.jobSeekerService.apiAccountUserPost$Response({body: JobSeekerData})
                                .pipe (
                                    catchError(error => {
                                        return EMPTY
                                    }),
                                    switchMap(( {body} ) => [
                                        actions.setJobSeekerRegisterUser({ JobSeekerData: body }),
                                    ]),
                                    tap(_=> [
                                        this.toast.success(this.i18n.t(this.registerJobSeekerToastConfirm)),
                                        this.localStorageService.set('isUserLoggedFrom', 'job-seekers'),
                                        this.router.navigate(['/home'])
                                    ])
                                )
                            : EMPTY
                    ))
                )
            )
        )
    )

    updateEmployerRegisterUser$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.updateEmployerRegisterUser),
            switchMap(({employerData})=> this.dialogService
                .openSaveConfirm(this.i18n.t(this.updateEmployerDialogConfirmTitle), this.i18n.t(this.updateEmployerDialogConfirmText))
                .afterClosed()
                .pipe(
                    switchMap(confirmed => (confirmed
                            ? this.employerService.apiAccountCompanyPut$Response({body: employerData})
                                .pipe (
                                    catchError(error => {
                                        return EMPTY
                                    }),
                                    switchMap(( {body} ) => [
                                        actions.setEmployerRegisterUser({ employerData: body }),
                                    ]),
                                    tap(_=> this.toast.success(this.i18n.t(this.updateEmployerToastConfirm)))
                                )
                            : EMPTY
                    ))
                )
            )
        )
    )

    updateJobSeekerRegisterUser$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.updateJobSeekerRegisterUser),
            switchMap(({JobSeekerData})=> this.dialogService
                .openSaveConfirm(this.i18n.t(this.updateJobSeekerDialogConfirmTitle), this.i18n.t(this.updateJobSeekerDialogConfirmText))
                .afterClosed()
                .pipe(
                    switchMap(confirmed => (confirmed
                            ? this.jobSeekerService.apiAccountUserPut$Response({body: JobSeekerData})
                                .pipe (
                                    catchError(error => {
                                        return EMPTY
                                    }),
                                    switchMap(( {body} ) => [
                                        actions.setJobSeekerRegisterUser({ JobSeekerData: body }),
                                    ]),
                                    tap(_=> this.toast.success(this.i18n.t(this.updateJobSeekerToastConfirm)))
                                )
                            : EMPTY
                    ))
                )
            )
        )
    )

    getEmployerRegister$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getEmployerRegisterUser),
            switchMap(_ => this.employerService.apiAccountCompanyGet()
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setEmployerRegisterUser({ employerData: data }),
                    ])
                )
        )
    ))

    getJobSeekerRegister$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getJobSeekerRegisterUser),
            switchMap(_ => this.jobSeekerService.apiAccountUserGet()
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setJobSeekerRegisterUser({ JobSeekerData: data }),
                    ])
                )
            )
        ))

    removeCurrentUserAccount$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.removeUserAccount),
            switchMap(()=> this.dialogService
                .openDeleteConfirm(this.i18n.t(this.removeUserAccountConfirmTitle), this.i18n.t(this.removeUserAccountConfirmText))
                .afterClosed()
                .pipe(
                    switchMap(confirmed => (confirmed
                            ? this.employerService.apiAccountCompanyDelete$Response()
                                .pipe (
                                    catchError(error => {
                                        return EMPTY
                                    }),
                                    switchMap(( {body} ) => [
                                        actions.setEmployerDataToInitial(),
                                    ]),
                                    tap(_=> [
                                        this.router.navigate(['/auth/logout'])
                                    ])
                                )
                            : EMPTY
                    ))
                )
            )
        )
    )

    removeCurrentJobSeekerAccount$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.removeJobSeekerAccount),
            switchMap(()=> this.dialogService
                .openDeleteConfirm(this.i18n.t(this.removeJobSeekerAccountConfirmTitle), this.i18n.t(this.removeJobSeekerAccountConfirmText))
                .afterClosed()
                .pipe(
                    switchMap(confirmed => (confirmed
                            ? this.jobSeekerService.apiAccountUserDelete$Response()
                                .pipe (
                                    catchError(error => {
                                        return EMPTY
                                    }),
                                    switchMap(( {body} ) => [
                                        actions.setEmployerDataToInitial(),
                                    ]),
                                    tap(_=> [
                                        this.router.navigate(['/auth/logout'])
                                    ])
                                )
                            : EMPTY
                    ))
                )
            )
        )
    )
}
