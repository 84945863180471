/* tslint:disable */
/* eslint-disable */
export enum JobMyAnnouncementsSortEnum {
  Created = 'Created',
  MaxSalary = 'MaxSalary',
  PositionName = 'PositionName',
  Status = 'Status',
  ExpiredDate = 'ExpiredDate',
  ApplicationCount = 'ApplicationCount'
}
