/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddJobAnnouncementDiscountCodeRequest } from '../models/add-job-announcement-discount-code-request';
import { AddJobAnnouncementRequest } from '../models/add-job-announcement-request';
import { GetJobAnnouncementsDto } from '../models/get-job-announcements-dto';
import { JobAnnouncementDto } from '../models/job-announcement-dto';
import { JobAnnouncementsSortEnum } from '../models/job-announcements-sort-enum';
import { JobCountryDto } from '../models/job-country-dto';
import { JobFormEnum } from '../models/job-form-enum';
import { JobMyAnnouncementsSortEnum } from '../models/job-my-announcements-sort-enum';
import { JobWorkloadEnum } from '../models/job-workload-enum';
import { SortDirectEnum } from '../models/sort-direct-enum';
import { UpdateJobAnnouncementRequest } from '../models/update-job-announcement-request';

@Injectable({
  providedIn: 'root',
})
export class JobAnnouncementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiJobAnnouncementIdGet
   */
  static readonly ApiJobAnnouncementIdGetPath = '/api/job/announcement/{id}';

  /**
   * Get by filter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAnnouncementIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAnnouncementIdGet$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<JobAnnouncementDto>> {

    const rb = new RequestBuilder(this.rootUrl, JobAnnouncementService.ApiJobAnnouncementIdGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobAnnouncementDto>;
      })
    );
  }

  /**
   * Get by filter.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAnnouncementIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAnnouncementIdGet(params: {
    id: string;
    context?: HttpContext
  }
): Observable<JobAnnouncementDto> {

    return this.apiJobAnnouncementIdGet$Response(params).pipe(
      map((r: StrictHttpResponse<JobAnnouncementDto>) => r.body as JobAnnouncementDto)
    );
  }

  /**
   * Path part for operation apiJobAnnouncementGet
   */
  static readonly ApiJobAnnouncementGetPath = '/api/job/announcement';

  /**
   * Get list by filter / sort by {created : asc|desc}, {maxSalary : asc|desc}, {bumpUp : asc|desc}.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAnnouncementGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAnnouncementGet$Response(params?: {

    /**
     * Terms
     */
    Terms?: string;

    /**
     * Company country as list
     */
    Country?: Array<string>;

    /**
     * SectorIds
     */
    SectorIds?: Array<number>;

    /**
     * PositionIds
     */
    PositionIds?: Array<number>;

    /**
     * Id&#x27;s of salary unit
     */
    SalaryUnitIds?: Array<number>;

    /**
     * SalaryFrom
     */
    SalaryFrom?: number;

    /**
     * SalaryTo
     */
    SalaryTo?: number;

    /**
     * OnlyRemote
     */
    OnlyRemote?: boolean;

    /**
     * OnlyWithSalary
     */
    OnlyWithSalary?: boolean;
    'SortItems.SortBy'?: JobAnnouncementsSortEnum;
    'SortItems.SortDirection'?: SortDirectEnum;
    'SortItems.Take'?: number;
    'SortItems.Skip'?: number;

    /**
     * FormTypes
     */
    FormTypes?: Array<JobFormEnum>;

    /**
     * Workloads
     */
    Workloads?: Array<JobWorkloadEnum>;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetJobAnnouncementsDto>> {

    const rb = new RequestBuilder(this.rootUrl, JobAnnouncementService.ApiJobAnnouncementGetPath, 'get');
    if (params) {
      rb.query('Terms', params.Terms, {});
      rb.query('Country', params.Country, {});
      rb.query('SectorIds', params.SectorIds, {});
      rb.query('PositionIds', params.PositionIds, {});
      rb.query('SalaryUnitIds', params.SalaryUnitIds, {});
      rb.query('SalaryFrom', params.SalaryFrom, {});
      rb.query('SalaryTo', params.SalaryTo, {});
      rb.query('OnlyRemote', params.OnlyRemote, {});
      rb.query('OnlyWithSalary', params.OnlyWithSalary, {});
      rb.query('SortItems.SortBy', params['SortItems.SortBy'], {});
      rb.query('SortItems.SortDirection', params['SortItems.SortDirection'], {});
      rb.query('SortItems.Take', params['SortItems.Take'], {});
      rb.query('SortItems.Skip', params['SortItems.Skip'], {});
      rb.query('FormTypes', params.FormTypes, {});
      rb.query('Workloads', params.Workloads, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetJobAnnouncementsDto>;
      })
    );
  }

  /**
   * Get list by filter / sort by {created : asc|desc}, {maxSalary : asc|desc}, {bumpUp : asc|desc}.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAnnouncementGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAnnouncementGet(params?: {

    /**
     * Terms
     */
    Terms?: string;

    /**
     * Company country as list
     */
    Country?: Array<string>;

    /**
     * SectorIds
     */
    SectorIds?: Array<number>;

    /**
     * PositionIds
     */
    PositionIds?: Array<number>;

    /**
     * Id&#x27;s of salary unit
     */
    SalaryUnitIds?: Array<number>;

    /**
     * SalaryFrom
     */
    SalaryFrom?: number;

    /**
     * SalaryTo
     */
    SalaryTo?: number;

    /**
     * OnlyRemote
     */
    OnlyRemote?: boolean;

    /**
     * OnlyWithSalary
     */
    OnlyWithSalary?: boolean;
    'SortItems.SortBy'?: JobAnnouncementsSortEnum;
    'SortItems.SortDirection'?: SortDirectEnum;
    'SortItems.Take'?: number;
    'SortItems.Skip'?: number;

    /**
     * FormTypes
     */
    FormTypes?: Array<JobFormEnum>;

    /**
     * Workloads
     */
    Workloads?: Array<JobWorkloadEnum>;
    context?: HttpContext
  }
): Observable<GetJobAnnouncementsDto> {

    return this.apiJobAnnouncementGet$Response(params).pipe(
      map((r: StrictHttpResponse<GetJobAnnouncementsDto>) => r.body as GetJobAnnouncementsDto)
    );
  }

  /**
   * Path part for operation apiJobAnnouncementPut
   */
  static readonly ApiJobAnnouncementPutPath = '/api/job/announcement';

  /**
   * Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAnnouncementPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiJobAnnouncementPut$Response(params?: {
    context?: HttpContext
    body?: UpdateJobAnnouncementRequest
  }
): Observable<StrictHttpResponse<JobAnnouncementDto>> {

    const rb = new RequestBuilder(this.rootUrl, JobAnnouncementService.ApiJobAnnouncementPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobAnnouncementDto>;
      })
    );
  }

  /**
   * Update.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAnnouncementPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiJobAnnouncementPut(params?: {
    context?: HttpContext
    body?: UpdateJobAnnouncementRequest
  }
): Observable<JobAnnouncementDto> {

    return this.apiJobAnnouncementPut$Response(params).pipe(
      map((r: StrictHttpResponse<JobAnnouncementDto>) => r.body as JobAnnouncementDto)
    );
  }

  /**
   * Path part for operation apiJobAnnouncementPost
   */
  static readonly ApiJobAnnouncementPostPath = '/api/job/announcement';

  /**
   * Add.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAnnouncementPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiJobAnnouncementPost$Response(params?: {
    context?: HttpContext
    body?: AddJobAnnouncementRequest
  }
): Observable<StrictHttpResponse<JobAnnouncementDto>> {

    const rb = new RequestBuilder(this.rootUrl, JobAnnouncementService.ApiJobAnnouncementPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobAnnouncementDto>;
      })
    );
  }

  /**
   * Add.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAnnouncementPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiJobAnnouncementPost(params?: {
    context?: HttpContext
    body?: AddJobAnnouncementRequest
  }
): Observable<JobAnnouncementDto> {

    return this.apiJobAnnouncementPost$Response(params).pipe(
      map((r: StrictHttpResponse<JobAnnouncementDto>) => r.body as JobAnnouncementDto)
    );
  }

  /**
   * Path part for operation apiJobAnnouncementDelete
   */
  static readonly ApiJobAnnouncementDeletePath = '/api/job/announcement';

  /**
   * Delete by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAnnouncementDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAnnouncementDelete$Response(params?: {

    /**
     * Id of announcement
     */
    Id?: string;

    /**
     * Id of account
     */
    AccountId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, JobAnnouncementService.ApiJobAnnouncementDeletePath, 'delete');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('AccountId', params.AccountId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Delete by id.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAnnouncementDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAnnouncementDelete(params?: {

    /**
     * Id of announcement
     */
    Id?: string;

    /**
     * Id of account
     */
    AccountId?: string;
    context?: HttpContext
  }
): Observable<string> {

    return this.apiJobAnnouncementDelete$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation apiJobAnnouncementMyGet
   */
  static readonly ApiJobAnnouncementMyGetPath = '/api/job/announcement/my';

  /**
   * Get my list by filter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAnnouncementMyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAnnouncementMyGet$Response(params?: {
    'SortItems.SortBy'?: JobMyAnnouncementsSortEnum;
    'SortItems.SortDirection'?: SortDirectEnum;
    'SortItems.Take'?: number;
    'SortItems.Skip'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetJobAnnouncementsDto>> {

    const rb = new RequestBuilder(this.rootUrl, JobAnnouncementService.ApiJobAnnouncementMyGetPath, 'get');
    if (params) {
      rb.query('SortItems.SortBy', params['SortItems.SortBy'], {});
      rb.query('SortItems.SortDirection', params['SortItems.SortDirection'], {});
      rb.query('SortItems.Take', params['SortItems.Take'], {});
      rb.query('SortItems.Skip', params['SortItems.Skip'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetJobAnnouncementsDto>;
      })
    );
  }

  /**
   * Get my list by filter.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAnnouncementMyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAnnouncementMyGet(params?: {
    'SortItems.SortBy'?: JobMyAnnouncementsSortEnum;
    'SortItems.SortDirection'?: SortDirectEnum;
    'SortItems.Take'?: number;
    'SortItems.Skip'?: number;
    context?: HttpContext
  }
): Observable<GetJobAnnouncementsDto> {

    return this.apiJobAnnouncementMyGet$Response(params).pipe(
      map((r: StrictHttpResponse<GetJobAnnouncementsDto>) => r.body as GetJobAnnouncementsDto)
    );
  }

  /**
   * Path part for operation apiJobAnnouncementDiscountCodePost
   */
  static readonly ApiJobAnnouncementDiscountCodePostPath = '/api/job/announcement/discount-code';

  /**
   * Add discount code.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAnnouncementDiscountCodePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiJobAnnouncementDiscountCodePost$Response(params?: {
    context?: HttpContext
    body?: AddJobAnnouncementDiscountCodeRequest
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, JobAnnouncementService.ApiJobAnnouncementDiscountCodePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Add discount code.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAnnouncementDiscountCodePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiJobAnnouncementDiscountCodePost(params?: {
    context?: HttpContext
    body?: AddJobAnnouncementDiscountCodeRequest
  }
): Observable<void> {

    return this.apiJobAnnouncementDiscountCodePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiJobAnnouncementCountriesGet
   */
  static readonly ApiJobAnnouncementCountriesGetPath = '/api/job/announcement/countries';

  /**
   * Get Job Announcement Countries.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiJobAnnouncementCountriesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAnnouncementCountriesGet$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<JobCountryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, JobAnnouncementService.ApiJobAnnouncementCountriesGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<JobCountryDto>>;
      })
    );
  }

  /**
   * Get Job Announcement Countries.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiJobAnnouncementCountriesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiJobAnnouncementCountriesGet(params?: {
    context?: HttpContext
  }
): Observable<Array<JobCountryDto>> {

    return this.apiJobAnnouncementCountriesGet$Response(params).pipe(
      map((r: StrictHttpResponse<Array<JobCountryDto>>) => r.body as Array<JobCountryDto>)
    );
  }

}
