import { Injectable } from '@angular/core';
import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';

@Injectable({
    providedIn: 'root'
})
export class I18nService {

    constructor() {
        i18next.use(HttpBackend).init({
            lng: 'pl',
            fallbackLng: 'pl',
            backend: {
                loadPath: 'assets/locales/{{lng}}/translation.json',
                crossDomain: true
            },
            resources:{
                en: {
                    translation: require('projects/beremotely-web/src/assets/locales/en/translation.json')
                },
                pl: {
                    translation: require('projects/beremotely-web/src/assets/locales/pl/translation.json')
                }
            }
        });
    }

    public t(key: string): string {
        return i18next.t(key);
    }
}
