import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {I18nService} from '@core/service/i18next.service';
import {Subject, takeUntil} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class ToastComponent implements OnInit, OnDestroy {
  @Output() onDangerToastClose = new EventEmitter<boolean>();
  toastCloseListener$ = new Subject<boolean>();
  text: string;
  linkName: string;
  linkHref: string;
  isDownload: boolean = true;
  type: string;
  isMobileView: boolean;
  destroyed$ = new Subject<void>();

  constructor(
      public sbRef: MatSnackBarRef<ToastComponent>,
      @Inject(MAT_SNACK_BAR_DATA) public data: any,
      public i18n: I18nService,
      private responsive: BreakpointObserver
  ) {
    this.text = data.text;
    this.type = data.type;
    this.linkName = data.linkName;
    this.linkHref = data.linkHref;
    this.isDownload = data.isDownload;
  }

  ngOnInit(): void {
    this.watchIfMobileSize();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  closeToast(): void {
    this.sbRef.dismiss();
    this.toastCloseListener$.next(true);
  }

  private watchIfMobileSize(): void {
    this.responsive.observe(
        [
          Breakpoints.Handset,
          Breakpoints.HandsetLandscape,
          Breakpoints.XSmall,
          Breakpoints.Small
        ]
    )
        .pipe(takeUntil(this.destroyed$))
        .subscribe(result => {
          this.isMobileView = result.matches;
        });
  }
}
