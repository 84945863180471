export const environment = {
  production: true,
  instance: "demo",
  useLocalStorage: true,
  printLog: true,
  apiUrl: "https://api-demo.beremotely.pl",
  localUrl: "https://demo.beremotely.pl",
  auth: {
    domain: "beremotelypl-demo.eu.auth0.com",
    clientId: "4x9epjG0GPz90B5G8zkuA8z1BhAwwZKG",
    audience: "https://demo-api.beremotely.pl",
    scope: "read",
  },
  gtag_id: 'G-8N1K22M8LZ',
  piksel_id: '627432239329944',
  gtag_menager: 'GTM-5465VBF'
};
