import {AccountMeDto} from '../../../@api/models/account-me-dto';
import {createReducer, on} from '@ngrx/store';
import * as actions from './users.actions';
import {AccountUserDto} from '@api/models/account-user-dto';
import {AccountCompanyDto} from '@api/models/account-company-dto';

export interface State {
    currentUser: {
        userStatus: AccountMeDto | null
        userData: AccountCompanyDto | AccountUserDto | null
    }
}

const initialState: State = {
    currentUser: {
        userStatus: null,
        userData: null
    }
}

export const reducer = createReducer(
    initialState,
    on(actions.setCurrentUserStatus, (state: State, { userStatus }) => ({
        ...state,
        currentUser: {
            ...state.currentUser,
            userStatus: userStatus,
        }
    })),
    on(actions.setEmployerRegisterUser, (state: State, { employerData }) => ({
        ...state,
        currentUser: {
            ...state.currentUser,
            userData: employerData
        }
    })),
    on(actions.setJobSeekerRegisterUser, (state: State, { JobSeekerData }) => ({
        ...state,
        currentUser: {
            ...state.currentUser,
            userData: JobSeekerData
        }
    })),
    on(actions.setEmployerDataToInitial, (state: State) => ({
        ...initialState
    })),
)

export const getCurrentUserStatus = (state: State) => state.currentUser.userStatus;
export const getCurrentUserData = (state: State) => state.currentUser.userData;
