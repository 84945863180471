import {createReducer, on} from '@ngrx/store';
import * as actions from './offers.actions';
import {JobSectorDto} from '@api/models/job-sector-dto';
import {JobSkillsTagDto} from '@api/models/job-skills-tag-dto';
import {JobAnnouncementDto} from '@api/models/job-announcement-dto';
import {
    GetApplicationsDto,
    GetJobAnnouncementsDto, GetStorageDto,
    JobAnnouncementsDto,
    JobAnnouncementStatusEnum,
    JobApplicationMethodEnum, JobCountryDto,
    JobFormEnum,
    JobPositionDto,
    JobSalaryUnitDto,
    JobTypeModeEnum,
    JobWorkloadEnum,
    PackagePaymentStatusEnum,
    PaymentOfferDto,
    PaymentOrderDto,
    PaymentPackageDto,
    PaymentPackageItemDto, PaymentStatusEnum, PaymentTransactionDto
} from '@api/models';
import {setOfferAddedId} from './offers.actions';

export interface State {
    offerData: JobAnnouncementDto,
    offerId: string,
    offersList: GetJobAnnouncementsDto,
    myOffersList: GetJobAnnouncementsDto,
    filtersData: {
        sectors: JobSectorDto[] | null,
        skills: JobSkillsTagDto[] | null,
        currency: JobSalaryUnitDto[] | null,
        experience: JobPositionDto[] | null
    }
    emptyResponse: any,
    paymentOffersList: PaymentOfferDto[],
    chosenPaymentOffersList: PaymentOfferDto[]
    payment: {
        paymentPackage: PaymentPackageDto,
        paymentPackageItems: PaymentPackageItemDto,
        paymentAuthOrder: PaymentOrderDto
    },
    countryList: JobCountryDto[],
    offerAddedId: string,
    packageAddedId: string,
    isOfferAdded: boolean,
    myApplicationList: GetApplicationsDto,
    storageData: GetStorageDto,
    updatedOfferData: JobAnnouncementDto,
    paymentResponse: PaymentTransactionDto
    totalOffersCount: number;
}

const initialState: State = {
    offerData: {
        aboutUs: null,
        acceptDataProcessing: false,
        acceptTermsOfService: false,
        applicationMethod: JobApplicationMethodEnum.Interior,
        clauseDataProcessing: null,
        clauseTermsOfService: null,
        description: null,
        exteriorMethodLink: null,
        id: '',
        isRecruitmentOnline: false,
        isSecretRecruitment: false,
        position: {
            id: 0,
            name: null,
            sectorIds: null,
        },
        positionId: 0,
        salaryUnit: {
            name: null
        },
        salaryUnitId: 0,
        sector: {
            id: 0,
            name: null,
            parentId: null,
        },
        sectorId: 0,
        title: null,
        status: JobAnnouncementStatusEnum.Create,
        typeMode: JobTypeModeEnum.Remote,
        workLoad: JobWorkloadEnum.FullTime,
    },
    offerId: '',
    offersList: {
        items: [{
            createdOn: '',
            customCompanyName: null,
            formType: JobFormEnum.B2B,
            id: '',
            locations: null,
            logo: null,
            maxSalaryTo: null,
            minSalaryFrom: null,
            positionName: null,
            salaryUnitName: null,
            sectorName: null,
            skillTags: null,
            status: JobAnnouncementStatusEnum.Create,
            title: null,
            typeMode: JobTypeModeEnum.Remote,
            updatedOn: null,
            workload: JobWorkloadEnum.FullTime
        }],
        count: 0
    },
    myOffersList: {
        items: [{
            createdOn: '',
            customCompanyName: null,
            formType: JobFormEnum.B2B,
            id: '',
            locations: null,
            logo: null,
            maxSalaryTo: null,
            minSalaryFrom: null,
            positionName: null,
            salaryUnitName: null,
            sectorName: null,
            skillTags: null,
            status: JobAnnouncementStatusEnum.Create,
            title: null,
            typeMode: JobTypeModeEnum.Remote,
            updatedOn: null,
            workload: JobWorkloadEnum.FullTime
        }],
        count: 0
    },
    filtersData: {
        sectors: null,
        skills: null,
        currency: null,
        experience: null
    },
    emptyResponse: null,
    paymentOffersList: [],
    chosenPaymentOffersList: [],
    payment: {
        paymentPackage: {
            id: '',
            paymentPackageItems: null,
            status: PackagePaymentStatusEnum.ToBePay,
            totalAmount: 0
        },
        paymentPackageItems: {
            id: '',
            paymentOfferId: '',
            paymentPackageId: ''
        },
        paymentAuthOrder: {
            orderId: null,
            redirectUri: null,
            status: null
        }
    },
    countryList: [{
        name: null
    }],
    offerAddedId: '',
    packageAddedId: '',
    isOfferAdded: false,
    myApplicationList: {
        count: 0,
        items: null
    },
    storageData: {
        content: null,
        fileName: null
    },
    updatedOfferData: {
        aboutUs: null,
        acceptDataProcessing: false,
        acceptTermsOfService: false,
        applicationMethod: JobApplicationMethodEnum.Interior,
        clauseDataProcessing: null,
        clauseTermsOfService: null,
        description: null,
        exteriorMethodLink: null,
        id: '',
        isRecruitmentOnline: false,
        isSecretRecruitment: false,
        position: {
            id: 0,
            name: null,
            sectorIds: null,
        },
        positionId: 0,
        salaryUnit: {
            name: null
        },
        salaryUnitId: 0,
        sector: {
            id: 0,
            name: null,
            parentId: null,
        },
        sectorId: 0,
        title: null,
        status: JobAnnouncementStatusEnum.Create,
        typeMode: JobTypeModeEnum.Remote,
        workLoad: JobWorkloadEnum.FullTime,
    },
    paymentResponse: {
        jobAnnouncementId: '',
        paymentPackageId: '',
        requestStatus: null,
        status: PaymentStatusEnum.New
    },
    totalOffersCount: 0
}

export const reducer = createReducer(
    initialState,
    on(actions.setSectorsData, (state: State, { sectorsData }) => ({
        ...state,
        filtersData: {
            ...state.filtersData,
            sectors: sectorsData
        }
    })),
    on(actions.setJobSkillsData, (state: State, { jobSkillsData }) => ({
        ...state,
        filtersData: {
            ...state.filtersData,
            skills: jobSkillsData
        }
    })),
    on(actions.setOfferAnnouncement, (state: State, { offerData }) => ({
        ...state,
        offerData: offerData
    })),
    on(actions.clearOfferAnnouncement, (state: State) => ({
        ...state,
        offerData: initialState.offerData
    })),
    on(actions.setOfferAnnouncementId, (state: State, { id }) => ({
        ...state,
        offerId: id
    })),
    on(actions.setCurrencyData, (state: State, { currency }) => ({
        ...state,
        filtersData: {
            ...state.filtersData,
            currency: currency
        }
    })),
    on(actions.setExperienceData, (state: State, { experience }) => ({
        ...state,
        filtersData: {
            ...state.filtersData,
            experience: experience
        }
    })),
    on(actions.setAllOfferAnnouncementList, (state: State, { offersList, isFromInfinite, isOnFirstLoad }) => ({
        ...state,
        offersList: {
            ...state.offersList,
            items: getCurrentOfferArray(state.offersList.items as Array<JobAnnouncementsDto>,offersList.items as Array<JobAnnouncementsDto>, isFromInfinite),
            count: offersList.count
        },
        totalOffersCount: !isOnFirstLoad ? offersList.count as number : state.totalOffersCount
    })),
    on(actions.setMyOfferAnnouncementList, (state: State, { offersList }) => ({
        ...state,
        myOffersList: {
            ...state.myOffersList,
            items: offersList.items,
            count: offersList.count
        }
    })),
    on(actions.setResponseEmptyAction, (state: State, { data }) => ({
        ...state,
        emptyResponse: data
    })),
    on(actions.setPaymentOffers, (state: State, { paymentOffer }) => ({
        ...state,
        paymentOffersList: paymentOffer
    })),
    on(actions.setChosenOffers, (state: State, { paymentOffer }) => ({
        ...state,
        chosenPaymentOffersList: paymentOffer
    })),
    on(actions.setPaymentPackage, (state: State, { paymentPackageRequest }) => ({
        ...state,
        payment: {
            ...state.payment,
            paymentPackage: paymentPackageRequest
        }
    })),
    on(actions.setPaymentPackageItem, (state: State, { paymentPackageItemRequest }) => ({
        ...state,
        payment: {
            ...state.payment,
            paymentPackageItems: paymentPackageItemRequest
        }
    })),
    on(actions.setPaymentAuthOrder, (state: State, { paymentOrderRequest }) => ({
        ...state,
        payment: {
            ...state.payment,
            paymentAuthOrder: paymentOrderRequest
        }
    })),
    on(actions.setPaymentOffersToDefault, (state: State) => ({
        ...state,
        offerData: initialState.offerData,
        payment: initialState.payment
    })),
    on(actions.setCountryData, (state: State, { countryData }) => ({
        ...state,
        countryList: countryData
    })),
    on(actions.setOfferAddedId, (state: State, { offerId, packageId }) => ({
        ...state,
        offerAddedId: offerId,
        packageAddedId: packageId
    })),
    on(actions.clearOfferAddedId, (state: State) => ({
        ...state,
        offerAddedId: ''
    })),
    on(actions.setIsOfferAdded, (state: State, { isAdded }) => ({
        ...state,
        isOfferAdded: isAdded
    })),
    on(actions.setMyApplicationList, (state: State, { applicationData }) => ({
        ...state,
        myApplicationList: applicationData
    })),
    on(actions.setStorageData, (state: State, { storageData }) => ({
        ...state,
        storageData: storageData
    })),
    on(actions.clearStorageData, (state: State) => ({
        ...state,
        storageData: initialState.storageData
    })),
    on(actions.setUpdateOfferAnnouncementFromSuspend, (state: State, { offerData }) => ({
        ...state,
        updatedOfferData: offerData
    })),
    on(actions.setPaymentResponse, (state: State, { paymentResponse }) => ({
        ...state,
        paymentResponse: paymentResponse
    })),
)

export const getCurrentOfferArray = (currentOffer: null | Array<JobAnnouncementsDto>,offers: null | Array<JobAnnouncementsDto>, isFromInfinite: boolean) => {
    if (isFromInfinite) {
        let arrayOfOffer: Array<JobAnnouncementsDto> | null = Array.from(currentOffer as Array<JobAnnouncementsDto>);
        arrayOfOffer.push(...offers as Array<JobAnnouncementsDto>);
        return arrayOfOffer;
    } else {
        return offers;
    }
}

export const getSectorsData = (state: State) => state.filtersData.sectors;
export const getSkillsData = (state: State) => state.filtersData.skills;
export const getCurrencyData = (state: State) => state.filtersData.currency;
export const getExperienceData = (state: State) => state.filtersData.experience;
export const getAllOffersList = (state: State) => state.offersList;
export const getMyOffersList = (state: State) => state.myOffersList;
export const getCurrentOffers = (state: State) => state.offerData;
export const getPaymentOffer = (state: State) => state.paymentOffersList;
export const getChosenPaymentOffer = (state: State) => state.chosenPaymentOffersList;
export const getChosenPackage = (state: State) => state.payment.paymentPackage;
export const getPaymentOrder = (state: State) => state.payment.paymentAuthOrder;
export const getCountryList = (state: State) => state.countryList;
export const getAddedOfferId = (state: State) => state.offerAddedId;
export const getAddedPackageId = (state: State) => state.packageAddedId;
export const getIsOfferAdded = (state: State) => state.isOfferAdded;
export const getOfferId = (state: State) => state.offerId;
export const getMyApplicationList = (state: State) => state.myApplicationList;
export const getStorageData = (state: State) => state.storageData;
export const getUpdatedOfferData = (state: State) => state.updatedOfferData;
export const getPaymentResponse = (state: State) => state.paymentResponse;
export const getTotalOffersCount = (state: State) => state.totalOffersCount;
