import {createAction, props} from '@ngrx/store';
import {JobSectorDto} from '@api/models/job-sector-dto';
import {JobSkillsTagDto} from '@api/models/job-skills-tag-dto';
import {JobAnnouncementDto} from '@api/models/job-announcement-dto';
import {AddJobAnnouncementRequest} from '@api/models/add-job-announcement-request';
import {JobSalaryUnitDto} from '@api/models/job-salary-unit-dto';
import {JobPositionDto} from '@api/models/job-position-dto';
import {JobAnnouncementsDto} from '@api/models/job-announcements-dto';
import {SortDirectEnum} from '@api/models/sort-direct-enum';
import {JobFormEnum} from '@api/models/job-form-enum';
import {JobWorkloadEnum} from '@api/models/job-workload-enum';
import {PaymentOfferDto} from '@api/models/payment-offer-dto';
import {AddPaymentPackageRequest} from '@api/models/add-payment-package-request';
import {PaymentPackageDto} from '@api/models/payment-package-dto';
import {AddPaymentPackageItemRequest} from '@api/models/add-payment-package-item-request';
import {PaymentPackageItemDto} from '@api/models/payment-package-item-dto';
import {PaymentOrderDto} from '@api/models/payment-order-dto';
import {GetJobAnnouncementsDto} from '@api/models/get-job-announcements-dto';
import {AccountAddressDto} from '@api/models/account-address-dto';
import {AddAppApplicationRequest} from '@api/models/add-app-application-request';
import {AddJobAnnouncementDiscountCodeRequest} from '@api/models/add-job-announcement-discount-code-request';
import {GetApplicationsDto} from '@api/models/get-applications-dto';
import {GetStorageDto} from '@api/models/get-storage-dto';
import {UpdateAppApplicationRequest} from '@api/models/update-app-application-request';
import {UpdateJobAnnouncementRequest} from '@api/models/update-job-announcement-request';
import {JobAnnouncementsSortEnum} from '@api/models/job-announcements-sort-enum';
import {JobMyAnnouncementsSortEnum} from '@api/models/job-my-announcements-sort-enum';
import {ApplicationSortEnum} from '@api/models/application-sort-enum';
import {PaymentTransactionDto} from '@api/models/payment-transaction-dto';
import {JobCountryDto} from '@api/models/job-country-dto';

export const getSectorsData = createAction('[OFFER] Get Sectors Data');
export const setSectorsData = createAction('[OFFER] Set Sectors Data', props<{ sectorsData: JobSectorDto[] }>());
export const getJobSkillsData = createAction('[OFFER] Get Job Skills Data');
export const setJobSkillsData= createAction('[OFFER] Set Job Skills Data', props<{ jobSkillsData: JobSkillsTagDto[] }>());
export const getCurrencyData = createAction('[OFFER] Get Currency Data');
export const setCurrencyData = createAction('[OFFER] Set Currency Data', props<{ currency: JobSalaryUnitDto[] }>());
export const getExperienceData = createAction('[OFFER] Get Experience Data', props<{ sectorId?: number }>());
export const setExperienceData = createAction('[OFFER] Set Experience Data', props<{ experience: JobPositionDto[] }>());

//OFFER
export const postOfferAnnouncement = createAction('[OFFER] Post Offer Announcement', props<{ offerData: AddJobAnnouncementRequest }>());
export const getOfferAnnouncement = createAction('[OFFER] Get Offer Announcement', props<{ offerId: string }>());
export const setOfferAnnouncement = createAction('[OFFER] Set Offer Announcement', props<{ offerData: JobAnnouncementDto }>());
export const clearOfferAnnouncement = createAction('[OFFER] Clear Offer Announcement');
export const setOfferAnnouncementId = createAction('[OFFER] Set Offer Announcement Id', props<{ id: string }>());
export const updateOfferAnnouncement = createAction('[OFFER] Update Offer Announcement', props<{ offerData: JobAnnouncementDto, isFromSuspend: boolean }>());
export const updateOfferAnnouncementFromSuspend = createAction('[OFFER] Update Offer Announcement from Suspend', props<{ offerData: UpdateJobAnnouncementRequest }>());
export const setUpdateOfferAnnouncementFromSuspend = createAction('[OFFER] Set Update Offer Announcement from Suspend', props<{ offerData: JobAnnouncementDto }>());
export const getAllOfferAnnouncementList = createAction('[OFFER] Get Offer List Announcement', props<{
    SectorIds?: Array<number>;
    PositionIds?: Array<number>;
    SalaryFrom?: number;
    SalaryTo?: number;
    OnlyRemote?: boolean;
    OnlyWithSalary?: boolean;
    FormTypes?: Array<JobFormEnum>;
    Workloads?: Array<JobWorkloadEnum>;
    Terms?: string;
    SalaryUnitIds?: Array<number>;
    Country?: Array<string>;
    'SortItems.SortBy'?: JobAnnouncementsSortEnum;
    'SortItems.SortDirection'?: SortDirectEnum;
    'SortItems.Take'?: number;
    'SortItems.Skip'?: number;
    isFromInfinite: boolean;
    isOnFirstLoad: boolean;
}>());

export const getMyOfferAnnouncementList = createAction('[OFFER] Get My Offer List Announcement', props<{
    'SortItems.SortBy'?: JobMyAnnouncementsSortEnum;
    'SortItems.SortDirection'?: SortDirectEnum;
    'SortItems.Take'?: number;
    'SortItems.Skip'?: number;
}>());
export const setAllOfferAnnouncementList = createAction('[OFFER] Set Offer List Announcement', props<{ offersList: GetJobAnnouncementsDto, isFromInfinite: boolean, isOnFirstLoad: boolean }>());
export const setMyOfferAnnouncementList = createAction('[OFFER] Set My Offer List Announcement', props<{ offersList: GetJobAnnouncementsDto }>());
export const deleteOfferAnnouncement = createAction('[OFFER] Delete Offer Announcement', props<{ offerId: string, userId: string }>());
export const setResponseEmptyAction = createAction('[OFFER] Set Empty Response Data', props<{ data: any }>());
export const getPaymentOffers = createAction('[OFFER] Get Payment Offers');
export const setPaymentOffers = createAction('[OFFER] Set Payment Offers', props<{ paymentOffer: PaymentOfferDto[] }>());
export const setPaymentOffersToDefault = createAction('[OFFER] Set Payment Offers Default');
export const setChosenOffers = createAction('[OFFER] Set Chosen Payment Offers', props<{ paymentOffer: PaymentOfferDto[] }>());
// PAYMENT //
export const savePaymentPackage = createAction('[OFFER] Save Payment Package', props<{ paymentPackageRequest: AddPaymentPackageRequest }>());
export const setPaymentPackage = createAction('[OFFER] Set Payment Package', props<{ paymentPackageRequest: PaymentPackageDto }>());
export const getPaymentPackage = createAction('[OFFER] Get Payment Package', props<{ paymentPackageRequest: {
        Id?: string;
        AccountId?: string;
        AnnouncementId?: string;
    } }>());
export const getPaymentPackageUnpaid = createAction('[OFFER] Get Payment Package Unpaid', props<{ paymentPackageRequest: {
        AccountId?: string;
        AnnouncementId?: string;
    } }>());
export const savePaymentPackageItem = createAction('[OFFER] Save Payment Package Item', props<{ paymentPackageItemRequest: AddPaymentPackageItemRequest }>());
export const setPaymentPackageItem = createAction('[OFFER] Set Payment Package Item', props<{ paymentPackageItemRequest: PaymentPackageItemDto }>());
export const removePaymentPackageItem = createAction('[OFFER] Remove Payment Package Item', props<{ paymentPackageItemRequest: {
        Id?: string;
        AccountId?: string;
        AnnouncementId?: string;
        PaymentPackageId?: string;
    } }>());

export const savePaymentAuthOrder = createAction('[OFFER] Save Payment Auth Order', props<{ paymentPackageItemRequest: {
        PaymentPackageId?: string;
        Description?: string;
    } }>());
export const setPaymentAuthOrder = createAction('[OFFER] Set Payment Auth Order', props<{ paymentOrderRequest: PaymentOrderDto }>());
export const getPaymentTransaction = createAction('[OFFER] Get Payment Transaction', props<{ paymentTransaction: {
        AccountId?: string;
        JobAnnouncementId?: string;
        PaymentPackageId?: string;
    }, isNotFromPublish?: boolean }>());
export const getPaymentTransactionNoPublish = createAction('[OFFER] Get Payment Transaction No Publish', props<{ paymentTransaction: {
        AccountId?: string;
        JobAnnouncementId?: string;
        PaymentPackageId?: string;
    }}>());
export const getCountryData= createAction('[FILTERS] Get Country Data');
export const setCountryData= createAction('[FILTERS] Set Country Data', props<{ countryData: JobCountryDto[] }>());
export const setOfferAddedId = createAction('[OFFER] Set Offer Added Id', props<{ offerId: string, packageId: string }>());
export const setPaymentResponse = createAction('[OFFER] Set Payment Response', props<{ paymentResponse: PaymentTransactionDto }>());
export const clearOfferAddedId = createAction('[OFFER] Clear Offer Added Id');
export const setIsOfferAdded = createAction('[OFFER] Set Is Offer Added', props<{ isAdded: boolean }>());
export const saveApplicationData = createAction('[OFFER] Save Application Data', props<{ applicationData: AddAppApplicationRequest }>());
export const getMyApplicationList = createAction('[OFFER] Get My Application List', props<{
    AccountId?: string;
    'SortItems.SortBy'?: ApplicationSortEnum;
    'SortItems.SortDirection'?: SortDirectEnum;
    'SortItems.Take'?: number;
    'SortItems.Skip'?: number;
}>());
export const setMyApplicationList = createAction('[OFFER] Set My Application List', props<{ applicationData: GetApplicationsDto }>());
export const updateMyApplication = createAction('[OFFER] Update My Application', props<{ applicationData: UpdateAppApplicationRequest }>());

export const savePromoCode = createAction('[OFFER] Save Promo Code', props<{ codeData: AddJobAnnouncementDiscountCodeRequest }>());
export const getStorageData = createAction('[OFFER] Get Storage Data', props<{ storageData: {AccountId: string, Id: string} }>());
export const setStorageData = createAction('[OFFER] Set Storage Data', props<{ storageData: GetStorageDto }>());
export const clearStorageData = createAction('[OFFER] Clear Storage Data');
