import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class MetaService {
    constructor(private title: Title, private meta: Meta) {}

    updateTitle(title: string) {
        if (title) {
            this.meta.updateTag({ property: 'og:title', content: title });
        }
    }

    updateDescription(description: string) {
        if (description) {
            this.meta.updateTag({ name: 'description', content: description });
            this.meta.updateTag({ property: 'og:description', content: description });
        }
    }

    updateOgTitle(ogTitle: string) {
        if (ogTitle) {
            this.meta.updateTag({ property: 'og:title', content: ogTitle });
        }
    }

    updateOgUrl(ogUrl: string) {
        if (ogUrl) {
            this.meta.updateTag({ property: 'og:url', content: ogUrl });
        }
    }

    updateOgImage(ogImage: string) {
        if (ogImage) {
            this.meta.updateTag({ property: 'og:image', content: ogImage });
        }
    }
}
