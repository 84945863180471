/* tslint:disable */
/* eslint-disable */
export enum JobAnnouncementStatusEnum {
  Create = 'Create',
  PaymentWaitToPay = 'PaymentWaitToPay',
  PaymentCancelled = 'PaymentCancelled',
  Published = 'Published',
  OutDate = 'OutDate',
  Archived = 'Archived'
}
