import {Injectable} from "@angular/core";
import {I18nService} from '../../service/i18next.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as actions from '@core/store/offers/offers.actions';
import {catchError, concatMap, EMPTY, of, switchMap, tap} from 'rxjs';
import {DialogService} from '@component/dialog/dialog.service';
import {createAction, Store} from '@ngrx/store';
import {ToastService} from '@component/toast/toast.service';
import {AccountCompanyService} from '@api/services/account-company.service';
import {Router} from '@angular/router';
import {JobSectorService} from '@api/services/job-sector.service';
import {JobSkillsTagService} from '@api/services/job-skills-tag.service';
import {JobAnnouncementService} from '@api/services/job-announcement.service';
import {AddJobAnnouncementRequest} from '@api/models/add-job-announcement-request';
import {JobSalaryUnitService} from '@api/services/job-salary-unit.service';
import {JobPositionService} from '@api/services/job-position.service';
import {SortDirectEnum} from '@api/models/sort-direct-enum';
import {PaymentOfferService} from '@api/services/payment-offer.service';
import {PaymentPackageService} from '@api/services/payment-package.service';
import {PaymentPackageItemService} from '@api/services/payment-package-item.service';
import {UpdateJobAnnouncementRequest} from "@api/models/update-job-announcement-request";
import {PaymentOrderService} from '@api/services/payment-order.service';
import {PaymentTransactionService} from '@api/services/payment-transaction.service';
import {HttpHeaderService} from '@service/http-header.service';
import {ApplicationService} from '@api/services/application.service';
import {HttpErrorResponse} from '@angular/common/http';
import {StorageService} from '@api/services/storage.service';
import {PaymentStatusEnum} from '@api/models/payment-status-enum';
import {JobMyAnnouncementsSortEnum} from '@api/models/job-my-announcements-sort-enum';
import {TagManagerService} from '@core/service/tag-manager.service';

@Injectable()
export class OffersEffects {
    deleteOfferDialogConfirmTitle = 'employerRegisterForm.form.dialogs.employerConfirmDeleteAnnouncementTitle';
    deleteOfferDialogConfirmText = 'employerRegisterForm.form.dialogs.employerConfirmDeleteAnnouncementText';
    paymentTransactionDialogConfirmTitle = 'employerRegisterForm.form.dialogs.paymentTransactionDialogConfirmTitle';
    paymentTransactionDialogConfirmText = 'employerRegisterForm.form.dialogs.paymentTransactionDialogConfirmText';
    paymentTransactionToastError = 'employerRegisterForm.form.toast.paymentTransactionToastError';
    saveApplicationDataDialogConfirmTitle = 'employerRegisterForm.form.dialogs.saveApplicationDataDialogConfirmTitle';
    saveApplicationDataDialogConfirmText = 'employerRegisterForm.form.dialogs.saveApplicationDataDialogConfirmText';
    saveApplicationDataToastSuccess = 'employerRegisterForm.form.dialogs.saveApplicationDataToastSuccess';
    updateOfferDialogConfirmTitle = 'employerRegisterForm.form.dialogs.updateOfferDialogConfirmTitle';
    updateOfferDialogConfirmText = 'employerRegisterForm.form.dialogs.updateOfferDialogConfirmText';
    paymentPackageItemDeleteFailed = createAction(
        '[PaymentPackage] Payment Package Item Delete Failed'
    );
    constructor(
        public i18n: I18nService,
        private actions$: Actions,
        private dialogService: DialogService,
        private store: Store,
        private toast: ToastService,
        private employerService: AccountCompanyService,
        private jobSectorService: JobSectorService,
        private jobSkillsService: JobSkillsTagService,
        private jobAnnouncementService: JobAnnouncementService,
        private jobCurrencyService: JobSalaryUnitService,
        private jobExperienceService: JobPositionService,
        private router: Router,
        private paymentOfferService: PaymentOfferService,
        private paymentPackageService: PaymentPackageService,
        private paymentPackageItemsService: PaymentPackageItemService,
        private paymentAuthOrderService: PaymentOrderService,
        private paymentTransactionService: PaymentTransactionService,
        private headerService: HttpHeaderService,
        private applicationService: ApplicationService,
        private storageService: StorageService,
        private tagManagerService: TagManagerService
    ) {}

    getSectorsData$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getSectorsData),
            switchMap(_ => this.jobSectorService.apiJobSectorGet()
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setSectorsData({ sectorsData: data }),
                    ])
                )
            )
        ))

    getSkillsData$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getJobSkillsData),
            switchMap(_ => this.jobSkillsService.apiJobSkillsTagGet()
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setJobSkillsData({ jobSkillsData: data }),
                    ])
                )
            )
        ))

    getCurrencyData$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getCurrencyData),
            switchMap(_ => this.jobCurrencyService.apiJobSalaryUnitGet()
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setCurrencyData({ currency: data }),
                    ])
                )
            )
        ))

    getExperienceData$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getExperienceData),
            switchMap(({sectorId}) => this.jobExperienceService.apiJobPositionGet({ SectorId: sectorId })
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setExperienceData({ experience: data }),
                    ])
                )
            )
        ))

    postOfferAnnouncement$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.postOfferAnnouncement),
            switchMap(params  => this.jobAnnouncementService.apiJobAnnouncementPost({ body: params.offerData as AddJobAnnouncementRequest })
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setOfferAnnouncement({ offerData: data }),
                        actions.setOfferAnnouncementId({ id: data?.id as string })
                    ])
                )
            )
        ))

    updateOfferAnnouncement$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.updateOfferAnnouncement),
            switchMap((params)=> this.dialogService
                .openSaveConfirm(this.i18n.t(this.updateOfferDialogConfirmTitle), this.i18n.t(this.updateOfferDialogConfirmText))
                .afterClosed()
                .pipe(
                    switchMap(confirmed => (confirmed
                            ? this.jobAnnouncementService.apiJobAnnouncementPut({body: params.offerData as UpdateJobAnnouncementRequest})
                                .pipe (
                                    catchError(error => {
                                        return EMPTY
                                    }),
                                    switchMap((data) => [
                                        actions.setOfferAnnouncement({ offerData: data })
                                    ])
                                )
                            : EMPTY
                    ))
                )
            )
        )
    )

    updateOfferAnnouncementFromSuspend$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.updateOfferAnnouncementFromSuspend),
            switchMap(params  => this.jobAnnouncementService.apiJobAnnouncementPut({ body: params.offerData as UpdateJobAnnouncementRequest })
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setUpdateOfferAnnouncementFromSuspend({ offerData: data }),
                    ])
                )
            )
        ))

    getOfferAnnouncement$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getOfferAnnouncement),
            switchMap(({ offerId })  => this.jobAnnouncementService.apiJobAnnouncementIdGet({ id: offerId })
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setOfferAnnouncement({ offerData: data }),
                    ])
                )
            )
        ))

    getOffersListAnnouncement$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getAllOfferAnnouncementList),
            switchMap((data)  => this.jobAnnouncementService.apiJobAnnouncementGet$Response({
                SectorIds: data.SectorIds,
                PositionIds: data.PositionIds,
                SalaryFrom: data.SalaryFrom,
                SalaryTo: data.SalaryTo,
                OnlyRemote: data.OnlyRemote,
                OnlyWithSalary: data.OnlyWithSalary,
                FormTypes: data.FormTypes,
                Workloads: data.Workloads,
                Terms: data.Terms,
                SalaryUnitIds: data?.SalaryUnitIds,
                Country: data?.Country,
                'SortItems.SortBy': data['SortItems.SortBy'],
                'SortItems.SortDirection': data['SortItems.SortDirection'],
                'SortItems.Take': data['SortItems.Take'],
                'SortItems.Skip': data['SortItems.Skip'],
                })
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( responseData  => [
                        actions.setAllOfferAnnouncementList({ offersList: responseData.body, isFromInfinite: data.isFromInfinite, isOnFirstLoad: data.isOnFirstLoad }),
                    ])
                )
            )
        ))

    getMyOffersListAnnouncement$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getMyOfferAnnouncementList),
            switchMap((data)  => this.jobAnnouncementService.apiJobAnnouncementMyGet$Response({
                    'SortItems.SortBy': data['SortItems.SortBy'],
                    'SortItems.SortDirection': data['SortItems.SortDirection'],
                    'SortItems.Take': data['SortItems.Take'],
                    'SortItems.Skip': data['SortItems.Skip'],
                })
                    .pipe(
                        catchError(error => {
                            return EMPTY
                        }),
                        switchMap( data  => [
                            actions.setMyOfferAnnouncementList({ offersList: data.body }),
                        ])
                    )
            )
        ))

    deleteOffersListAnnouncement$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.deleteOfferAnnouncement),
            switchMap(({ offerId, userId })=> this.dialogService
                .openDeleteConfirm(this.i18n.t(this.deleteOfferDialogConfirmTitle), this.i18n.t(this.deleteOfferDialogConfirmText))
                .afterClosed()
                .pipe(
                    switchMap(confirmed => (confirmed
                            ? this.jobAnnouncementService.apiJobAnnouncementDelete({ Id: offerId, AccountId: userId })
                                .pipe (
                                    catchError(error => {
                                        return EMPTY
                                    }),
                                    switchMap((data) => [
                                        actions.setResponseEmptyAction({ data }),
                                        actions.getMyOfferAnnouncementList( {
                                            'SortItems.SortBy': JobMyAnnouncementsSortEnum.Status,
                                            'SortItems.SortDirection': SortDirectEnum.Asc,
                                            'SortItems.Take': 30,
                                            'SortItems.Skip': 0,
                                        })
                                    ])
                                )
                            : EMPTY
                    ))
                )
            )
        )
    )

    getPaymentOffers$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getPaymentOffers),
            switchMap(_ => this.paymentOfferService.apiPaymentOfferGet()
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setPaymentOffers({ paymentOffer: data }),
                    ])
                )
            )
        ))

    savePaymentPackage$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.savePaymentPackage),
            switchMap(params  => this.paymentPackageService.apiPaymentPackagePost({ body: params.paymentPackageRequest })
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => {
                        return of(actions.setPaymentPackage({paymentPackageRequest: data}))
                    })
                )
            )
        ))

    getPaymentPackage$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getPaymentPackage),
            switchMap(params  => this.paymentPackageService.apiPaymentPackageGet({
                    Id: params?.paymentPackageRequest?.Id,
                    AccountId: params?.paymentPackageRequest?.AccountId,
                    AnnouncementId: params?.paymentPackageRequest?.AnnouncementId,
                })
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => {
                        return of(actions.setPaymentPackage({paymentPackageRequest: data}))
                    })
                )
            )
        ))

    getPaymentPackageUnpaid$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getPaymentPackageUnpaid),
            switchMap(params  => this.paymentPackageService.apiPaymentPackageUnpaidGet({
                    AccountId: params?.paymentPackageRequest?.AccountId,
                    AnnouncementId: params?.paymentPackageRequest?.AnnouncementId,
                })
                    .pipe(
                        catchError(error => {
                            return EMPTY
                        }),
                        switchMap( data  => [
                            actions.setPaymentPackage({paymentPackageRequest: data})
                        ])
                    )
            )
        ))

    savePaymentPackageItem$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.savePaymentPackageItem),
            switchMap(params  => this.paymentPackageItemsService.apiPaymentPackageItemPost({ body: params.paymentPackageItemRequest })
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setPaymentPackageItem({ paymentPackageItemRequest: data }),
                        actions.getPaymentPackageUnpaid( {paymentPackageRequest: {
                                AccountId: params?.paymentPackageItemRequest?.accountId,
                                AnnouncementId: params?.paymentPackageItemRequest?.announcementId,
                            }} )
                    ])
                )
            )
        ))

    removePaymentPackageItem$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.removePaymentPackageItem),
            concatMap(params  => this.paymentPackageItemsService.apiPaymentPackageItemDelete({
                Id: params?.paymentPackageItemRequest?.Id,
                AccountId: params?.paymentPackageItemRequest?.AccountId,
                AnnouncementId: params?.paymentPackageItemRequest?.AnnouncementId,
                PaymentPackageId: params?.paymentPackageItemRequest?.PaymentPackageId,
                })
                .pipe(
                    switchMap( data  => [
                        actions.getPaymentPackageUnpaid( {paymentPackageRequest: {
                                AccountId: params?.paymentPackageItemRequest?.AccountId,
                                AnnouncementId: params?.paymentPackageItemRequest?.AnnouncementId,
                            }} )
                    ]),
                    catchError(error => {
                        return of(this.paymentPackageItemDeleteFailed())
                    })
                )
            )
        ))

    savePaymentAuthOrder = createEffect(() => this.actions$
        .pipe(
            ofType(actions.savePaymentAuthOrder),
            switchMap(params  => this.paymentAuthOrderService.apiPaymentOrderGet({
                    PaymentPackageId: params?.paymentPackageItemRequest?.PaymentPackageId,
                    Description: params?.paymentPackageItemRequest?.Description
                })
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setPaymentAuthOrder({ paymentOrderRequest: data }),
                    ])
                )
            )
        ))

    getPaymentTransaction$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getPaymentTransaction),
            switchMap(({ paymentTransaction} ) => this.dialogService
                .openSaveConfirm(this.i18n.t(this.paymentTransactionDialogConfirmTitle), this.i18n.t(this.paymentTransactionDialogConfirmText))
                .afterClosed()
                .pipe(
                    switchMap(confirmed => (confirmed
                            ? this.paymentTransactionService.apiPaymentTransactionGet({
                                AccountId: paymentTransaction?.AccountId,
                                JobAnnouncementId: paymentTransaction?.JobAnnouncementId,
                                PaymentPackageId: paymentTransaction?.PaymentPackageId
                            })
                                .pipe (
                                    catchError(error => {
                                        this.toast.danger(this.i18n.t(this.paymentTransactionToastError))
                                        return EMPTY
                                    }),
                                    tap((data) => {
                                        if (data?.status === PaymentStatusEnum.Complete) {
                                            this.router.navigate(['/user/profile/employers/offer-added'])
                                        } else {
                                            this.router.navigate(['/user/profile/employers/offer-unpaid'])
                                        }
                                    }),
                                    switchMap((data) => [
                                        actions.setOfferAddedId({ offerId: paymentTransaction?.AccountId as string, packageId: paymentTransaction?.PaymentPackageId as string }),
                                        actions.setIsOfferAdded({ isAdded: true }),
                                        actions.setPaymentResponse({ paymentResponse: data })
                                    ])
                                )
                            : EMPTY
                    ))
                )
            )
        )
    )

    getPaymentTransactionNoPublish$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getPaymentTransactionNoPublish),
            switchMap(({ paymentTransaction}) => this.paymentTransactionService.apiPaymentTransactionGet({
                    AccountId: paymentTransaction?.AccountId,
                    JobAnnouncementId: paymentTransaction?.JobAnnouncementId,
                    PaymentPackageId: paymentTransaction?.PaymentPackageId
                })
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setPaymentResponse({ paymentResponse: data })
                    ])
                )
            )
        ))
    // @ts-ignore
    getCountryData$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getCountryData),
            switchMap(_ => this.jobAnnouncementService.apiJobAnnouncementCountriesGet()
                .pipe(
                    catchError(error => {
                        return EMPTY
                    }),
                    switchMap( data  => [
                        actions.setCountryData({ countryData: data }),
                    ])
                )
            )
        ))

    saveApplicationData$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.saveApplicationData),
            switchMap(({ applicationData} ) => this.dialogService
                .openSaveConfirm(this.i18n.t(this.saveApplicationDataDialogConfirmTitle), this.i18n.t(this.saveApplicationDataDialogConfirmText))
                .afterClosed()
                .pipe(
                    switchMap(confirmed => (confirmed
                            ? this.applicationService.apiApplicationPost({ body: applicationData  })
                                .pipe (
                                    catchError(error => {
                                        return EMPTY
                                    }),
                                    switchMap((data) => [
                                        actions.setResponseEmptyAction({ data }),
                                    ]),
                                    tap(() => {
                                        this.toast.success(this.i18n.t(this.saveApplicationDataToastSuccess));
                                        this.router.navigate(['/offers'])
                                        this.tagManagerService.applicationClick('application_finished_internal')
                                    })
                                )
                            : EMPTY
                    ))
                )
            )
        )
    )

    savePromoCode = createEffect(() => this.actions$
        .pipe(
            ofType(actions.savePromoCode),
            switchMap(({ codeData })  => this.jobAnnouncementService.apiJobAnnouncementDiscountCodePost({ body: codeData })
                    .pipe(
                        catchError((error: HttpErrorResponse) => {

                            return EMPTY;
                        }),
                        switchMap( data  => [
                            actions.setResponseEmptyAction({ data }),
                        ])
                    )
            )
        ))

    getApplicationListAnnouncement$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getMyApplicationList),
            switchMap((data)  => this.applicationService.apiApplicationMeGet({
                AccountId: data.AccountId,
                'SortItems.SortBy': data['SortItems.SortBy'],
                'SortItems.SortDirection': data['SortItems.SortDirection'],
                'SortItems.Take': data['SortItems.Take'],
                'SortItems.Skip': data['SortItems.Skip'],
                })
                    .pipe(
                        catchError(error => {
                            return EMPTY
                        }),
                        switchMap( data  => [
                            actions.setMyApplicationList({ applicationData: data }),
                        ])
                    )
            )
        ))

    updateApplicationAnnouncement$ = createEffect(() => this.actions$
        .pipe(
            ofType(actions.updateMyApplication),
            switchMap(({applicationData})  => this.applicationService.apiApplicationPut({ body: applicationData })
                    .pipe(
                        catchError(error => {
                            return EMPTY
                        }),
                        switchMap( data  => [
                            actions.setResponseEmptyAction({data: data})
                        ])
                    )
            )
        ))

    getStorageData = createEffect(() => this.actions$
        .pipe(
            ofType(actions.getStorageData),
            switchMap(({ storageData })  => this.storageService.apiStorageGet({ AccountId: storageData.AccountId, Id: storageData.Id})
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return EMPTY;
                    }),
                    switchMap( storageData  => [
                        actions.setStorageData({ storageData }),
                    ])
                )
            )
        ))
}
