import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, tap} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpHeaderService {
  lol = 'https://localhost:7013/api/job/announcement/list?Terms=&SalaryFrom=0&OnlyRemote=false&OnlyWithSalary=false&SortItems.SortBy=bumpUp&SortItems.SortDirection=Asc&SortItems.Take=20&SortItems.Skip=0'

  constructor(private http: HttpClient) {
    this.fetchData();
  }

    fetchData(): Observable<any> {
        return this.http.get<any>(this.lol, { observe: 'response' })
            .pipe(
                tap((res: HttpResponse<any>) => {
                    const totalCount = res.headers.get('x-total-count');
                })
            )
    }
}
