import {createAction, props} from '@ngrx/store';
import {AccountMeDto} from '../../../@api/models/account-me-dto';
import {AccountCompanyDto} from '@api/models/account-company-dto';
import {UpdateAccountCompanyRequest} from '@api/models/update-account-company-request';
import {AccountUserDto} from '@api/models/account-user-dto';
import {UpdateAccountUserRequest} from '@api/models/update-account-user-request';

//user
export const setCurrentUserStatus = createAction('[USERS] Set Current User Status', props<{ userStatus: AccountMeDto }>());

//employer
export const getEmployerRegisterUser = createAction('[USERS EMPLOYER] Get Employer Register User');
export const saveEmployerRegisterUser = createAction('[USERS EMPLOYER] Save Employer Register User', props<{ employerData: AccountCompanyDto }>());
export const updateEmployerRegisterUser = createAction('[USERS EMPLOYER] Update Employer User Data', props<{ employerData: UpdateAccountCompanyRequest }>());
export const setEmployerRegisterUser = createAction('[USERS EMPLOYER] Set Employer Register User', props<{ employerData: AccountCompanyDto }>());
export const removeUserAccount = createAction('[USERS EMPLOYER] Remove Current Employer Account');
export const setEmployerDataToInitial = createAction('[USERS EMPLOYER] Set Employer Data To Initial');

//job-seeker
export const getJobSeekerRegisterUser = createAction('[USERS JOBSEEKER] Get JobSeeker Register User');
export const saveJobSeekerRegisterUser = createAction('[USERS JOBSEEKER] Save JobSeeker Register User', props<{ JobSeekerData: AccountUserDto }>());
export const updateJobSeekerRegisterUser = createAction('[USERS JOBSEEKER] Update JobSeeker User Data', props<{ JobSeekerData: UpdateAccountUserRequest }>());
export const setJobSeekerRegisterUser = createAction('[USERS JOBSEEKER] Set JobSeeker Register User', props<{ JobSeekerData: AccountUserDto }>());
export const removeJobSeekerAccount = createAction('[USERS JOBSEEKER] Remove Current JobSeeker Account');
