import {Injectable} from "@angular/core";
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent } from './dialog.component';
import {DialogTypeModel} from "../../@core/enums/dialog-type.model";
import {I18nService} from '@core/service/i18next.service';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(
        private dialog: MatDialog,
        public i18n: I18nService,

    ) { }

    public openSaveConfirm(title?: string, text?: string, text2?: string, link?: string, linkText?: string) {
        const confirmValue = this.i18n.t('dialogs.confirmDialog.confirm');
        const cancelValue = this.i18n.t('dialogs.confirmDialog.cancel');
        return this.dialog.open(DialogComponent, {
            width: 'auto',
            height:'auto',
            data: {
                dialogType: DialogTypeModel.saveConfirm,
                dialogTitle: title || '',
                dialogText: text || '',
                dialogText2: text2 || '',
                link: link || '',
                linkText: linkText || '',
                dialogYes: confirmValue,
                dialogNo: cancelValue,
            },
        });
    }

    public openDeleteConfirm(title?: string, text?: string) {
        const deleteValue = this.i18n.t('dialogs.confirmDialog.delete');
        const cancelValue = this.i18n.t('dialogs.confirmDialog.cancel');
        return this.dialog.open(DialogComponent, {
            width: 'auto',
            height:'auto',
            data: {
                dialogType: DialogTypeModel.deleteConfirm,
                dialogTitle: title || '',
                dialogText: text || '',
                dialogYes: deleteValue,
                dialogNo: cancelValue,
            },
        });
    }

    public openConfirmValue(title?: string, text?: string, confirmValue?: string) {
        const confirm = this.i18n.t('dialogs.confirmDialog.confirm');
        const cancelValue = this.i18n.t('dialogs.confirmDialog.cancel');
        return this.dialog.open(DialogComponent, {
            width: 'auto',
            height:'auto',
            data: {
                dialogType: DialogTypeModel.confirmValue,
                dialogTitle: title || '',
                dialogText: text || '',
                valueToConfirm: confirmValue || '',
                dialogYes: confirm,
                dialogNo: cancelValue,
            },
        });
    }
}
