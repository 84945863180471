import {Component, OnDestroy, OnInit} from '@angular/core';
import {I18nService} from '@core/service/i18next.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Subject, takeUntil} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-empty-data',
  templateUrl: './empty-data.component.html',
  styleUrls: ['./empty-data.component.sass']
})
export class EmptyDataComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<void>();
  isMobileView: boolean;
  constructor(
      public i18n: I18nService,
      private responsive: BreakpointObserver,
      private router: Router
  ) {}

  ngOnInit(): void {
    this.watchIfMobileSize();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private watchIfMobileSize(): void {
    this.responsive.observe(
        [
          Breakpoints.Handset,
          Breakpoints.HandsetLandscape,
          Breakpoints.XSmall,
          Breakpoints.Small
        ]
    )
        .pipe(takeUntil(this.destroyed$))
        .subscribe(result => {
          this.isMobileView = result.matches;
        });
  }

    navigateToHomePage(): void {
        this.router.navigate(['/offers'])
    }
}
