import { NgModule } from '@angular/core';
import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import {I18nService} from '@core/service/i18next.service';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {metaReducers, reducers} from '@core/store';
import {UsersEffects} from '@core/store/users/users.effects';
import {ComponentsModule} from '@component/component.module';
import {OffersEffects} from '@core/store/offers/offers.effects';
import {ShortenTextPipe} from '@core/pipes/shorten-text.pipe';
import {DecimalNumberPipe} from '@core/pipes/decimalNumber.pipe';

@NgModule({
    declarations: [ShortenTextPipe],
    imports: [
        CommonModule,
        ComponentsModule,
        EffectsModule.forRoot(
            [
                UsersEffects,
                OffersEffects
            ]),
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictActionImmutability: true,
                strictStateImmutability: true,
            }
        }),

        StoreDevtoolsModule.instrument({
            maxAge: 25,
        })
    ],
    providers: [I18nService],
    exports: [
        ShortenTextPipe
    ]
})
export class CoreModule {
    constructor() {
        i18next.use(HttpBackend).init({
            lng: 'en',
            fallbackLng: 'en',
            backend: {
                loadPath: 'assets/locales/{{lng}}/translation.json',
                crossDomain: true
            },
            resources:{
                en: {
                    translation: require('projects/beremotely-web/src/assets/locales/en/translation.json')
                },
                pl: {
                    translation: require('projects/beremotely-web/src/assets/locales/pl/translation.json')
                }
            }
        });
    }
}
