import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from './material.module';
import {DialogComponent} from './dialog/dialog.component';
import {ToastComponent} from './toast/toast.component';
import {ButtonComponent} from './button/button.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DragAndDropComponent} from '@component/drag-and-drop/drag-and-drop.component';
import {FileDragAndDropDirective} from '@core/directives/drag-and-drop.directive';
import {EmptyDataComponent} from '@component/empty-data/empty-data.component';

const sharedComponents: any = [
    DialogComponent,
    ToastComponent,
    ButtonComponent,
    DragAndDropComponent,
    FileDragAndDropDirective,
    EmptyDataComponent
]

@NgModule({
    declarations: [
        ...sharedComponents
    ],
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        DragDropModule,
    ],
    exports: [
        ...sharedComponents,
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        DragDropModule
    ],
})
export class ComponentsModule { }
