<div>
    <div>
        <div class="dropzone" fileDragDrop [class.isFileChosenCorrectly]="!isFileChosenCorrectly" (filesChangeEmitter)="onFileChange($event)">
            <div #previewContainer class="preview-container"></div>
            <div class="text-wrapper">
                <div class="centered">
                    <input #fileInput *ngIf="!isFileChosen"  type="file" name="file" id="file" (change)="onFileChange($any($event.target).files)" multiple>
                    <label *ngIf="!isFileChosen"  for="file">
                        <mat-icon>note_add</mat-icon>
                        {{i18n.t('userProfile.employer.addOffer.offerForm.companyInfo.addFile')}}
                    </label>
                </div>
            </div>
        </div>
        <span
            *ngIf="!isFileChosen"
            class="limit"
        >
            {{i18n.t('userProfile.employer.addOffer.offerForm.companyInfo.fileFormat')}}: {{allowedFileTypesString}}. {{i18n.t('userProfile.employer.addOffer.offerForm.companyInfo.fileSize')}}: max {{isImage ? '200kB' : '1MB'}}
        </span>
        <span *ngIf="isFileChosen" class="differentFile" (click)="chooseDifferentFile()">{{i18n.t('userProfile.employer.addOffer.offerForm.companyInfo.chooseDifferent')}}</span>
    </div>
</div>
