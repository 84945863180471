import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromUsers from './users/users.reducer';
import * as fromFilters from './offers/offers.reducer';

export const usersKey = 'users';
export const filtersKey = 'offers';

export interface State {
    [usersKey]: fromUsers.State,
    [filtersKey]: fromFilters.State
}

export const reducers: ActionReducerMap<State> = {
    [usersKey]: fromUsers.reducer,
    [filtersKey]: fromFilters.reducer
}

export const metaReducers: MetaReducer<State>[] = [];
