/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddPaymentPackageRequest } from '../models/add-payment-package-request';
import { PaymentPackageDto } from '../models/payment-package-dto';

@Injectable({
  providedIn: 'root',
})
export class PaymentPackageService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPaymentPackageGet
   */
  static readonly ApiPaymentPackageGetPath = '/api/payment/package';

  /**
   * Get by filter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentPackageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentPackageGet$Response(params?: {

    /**
     * Id of PaymentPackage
     */
    Id?: string;

    /**
     * Id of Account
     */
    AccountId?: string;

    /**
     * Id of Announcement
     */
    AnnouncementId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaymentPackageDto>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentPackageService.ApiPaymentPackageGetPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {});
      rb.query('AccountId', params.AccountId, {});
      rb.query('AnnouncementId', params.AnnouncementId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentPackageDto>;
      })
    );
  }

  /**
   * Get by filter.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPaymentPackageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentPackageGet(params?: {

    /**
     * Id of PaymentPackage
     */
    Id?: string;

    /**
     * Id of Account
     */
    AccountId?: string;

    /**
     * Id of Announcement
     */
    AnnouncementId?: string;
    context?: HttpContext
  }
): Observable<PaymentPackageDto> {

    return this.apiPaymentPackageGet$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentPackageDto>) => r.body as PaymentPackageDto)
    );
  }

  /**
   * Path part for operation apiPaymentPackagePost
   */
  static readonly ApiPaymentPackagePostPath = '/api/payment/package';

  /**
   * Add.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentPackagePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPaymentPackagePost$Response(params?: {
    context?: HttpContext
    body?: AddPaymentPackageRequest
  }
): Observable<StrictHttpResponse<PaymentPackageDto>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentPackageService.ApiPaymentPackagePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentPackageDto>;
      })
    );
  }

  /**
   * Add.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPaymentPackagePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPaymentPackagePost(params?: {
    context?: HttpContext
    body?: AddPaymentPackageRequest
  }
): Observable<PaymentPackageDto> {

    return this.apiPaymentPackagePost$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentPackageDto>) => r.body as PaymentPackageDto)
    );
  }

  /**
   * Path part for operation apiPaymentPackageUnpaidGet
   */
  static readonly ApiPaymentPackageUnpaidGetPath = '/api/payment/package/unpaid';

  /**
   * Get by filter.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentPackageUnpaidGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentPackageUnpaidGet$Response(params?: {

    /**
     * Id of Account
     */
    AccountId?: string;

    /**
     * Id of Announcement
     */
    AnnouncementId?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PaymentPackageDto>> {

    const rb = new RequestBuilder(this.rootUrl, PaymentPackageService.ApiPaymentPackageUnpaidGetPath, 'get');
    if (params) {
      rb.query('AccountId', params.AccountId, {});
      rb.query('AnnouncementId', params.AnnouncementId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentPackageDto>;
      })
    );
  }

  /**
   * Get by filter.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPaymentPackageUnpaidGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentPackageUnpaidGet(params?: {

    /**
     * Id of Account
     */
    AccountId?: string;

    /**
     * Id of Announcement
     */
    AnnouncementId?: string;
    context?: HttpContext
  }
): Observable<PaymentPackageDto> {

    return this.apiPaymentPackageUnpaidGet$Response(params).pipe(
      map((r: StrictHttpResponse<PaymentPackageDto>) => r.body as PaymentPackageDto)
    );
  }

}
