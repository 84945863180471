import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  { path: "auth", loadChildren: () => import('@module/index').then(m => m.AuthModule)},
  { path: "", loadChildren: () => import('@shared/index').then(m => m.LayoutModule)},
  { path: "**", redirectTo: "", pathMatch: "full"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
