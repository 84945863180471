<div class="sector" [class.sectorMobileView]="isMobileView">
    <img src="assets/image/icons/symbol_big_nobg.png" alt="beRemotely logo">
    <p><span>404</span> {{i18n.t('general.404')}}</p>
    <app-button
            class="btnContainer"
            [class.btnContainerMobile]="isMobileView"
            [buttonTitle]="i18n.t('general.404Button')"
            [buttonType]="'submit'"
            [width]="'auto'"
            (onBtnClick)="navigateToHomePage()"
    ></app-button>
</div>
