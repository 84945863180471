import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef,  MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogModel } from '@core/models/dialog';
import { DialogTypeModel} from "@core/enums/dialog-type.model";
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {I18nService} from '@core/service/i18next.service';
import {Subject, takeUntil} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.sass']
})
export class DialogComponent implements OnInit {
  dialogTypeModel = DialogTypeModel;
  confirmValueForm: UntypedFormGroup
  isConfirmValueCorrect: boolean = false;
  destroyed$ = new Subject<void>();
  isMobileView: boolean;

  constructor(
      public dialogRef: MatDialogRef<DialogModel>,
      private fb: FormBuilder,
      public i18n: I18nService,
      private responsive: BreakpointObserver,
      @Inject(MAT_DIALOG_DATA) public data: DialogModel
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.watchIfMobileSize();
  }

  onConfirmValueChange(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    this.isConfirmValueCorrect = value === this.data.valueToConfirm;
  }

  public cancel() {
    this.close(false);
  }

  public close(value:any) {
    this.dialogRef.close(value);
  }

  public confirm() {
    this.close(true);
  }

  private createForm(): void {
    this.confirmValueForm = this.fb.group({
      valueToConfirm: ['', Validators.required],
    })
  }

  private watchIfMobileSize(): void {
    this.responsive.observe(
        [
          Breakpoints.Handset,
          Breakpoints.HandsetLandscape,
          Breakpoints.XSmall,
          Breakpoints.Small,
          // Breakpoints.HandsetLandscape,
          // Breakpoints.HandsetLandscape,
          // Breakpoints.HandsetLandscape,
        ]
    )
        .pipe(takeUntil(this.destroyed$))
        .subscribe(result => {
          this.isMobileView = result.matches;
        });
  }
}
