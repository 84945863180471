/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddAppApplicationRequest } from '../models/add-app-application-request';
import { ApplicationSortEnum } from '../models/application-sort-enum';
import { GetApplicationDto } from '../models/get-application-dto';
import { GetApplicationsDto } from '../models/get-applications-dto';
import { SortDirectEnum } from '../models/sort-direct-enum';
import { UpdateAppApplicationRequest } from '../models/update-app-application-request';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiApplicationMeGet
   */
  static readonly ApiApplicationMeGetPath = '/api/application/me';

  /**
   * Get applications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationMeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationMeGet$Response(params?: {

    /**
     * Id of company
     */
    AccountId?: string;
    'SortItems.SortBy'?: ApplicationSortEnum;
    'SortItems.SortDirection'?: SortDirectEnum;
    'SortItems.Take'?: number;
    'SortItems.Skip'?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<GetApplicationsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationService.ApiApplicationMeGetPath, 'get');
    if (params) {
      rb.query('AccountId', params.AccountId, {});
      rb.query('SortItems.SortBy', params['SortItems.SortBy'], {});
      rb.query('SortItems.SortDirection', params['SortItems.SortDirection'], {});
      rb.query('SortItems.Take', params['SortItems.Take'], {});
      rb.query('SortItems.Skip', params['SortItems.Skip'], {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetApplicationsDto>;
      })
    );
  }

  /**
   * Get applications.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationMeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiApplicationMeGet(params?: {

    /**
     * Id of company
     */
    AccountId?: string;
    'SortItems.SortBy'?: ApplicationSortEnum;
    'SortItems.SortDirection'?: SortDirectEnum;
    'SortItems.Take'?: number;
    'SortItems.Skip'?: number;
    context?: HttpContext
  }
): Observable<GetApplicationsDto> {

    return this.apiApplicationMeGet$Response(params).pipe(
      map((r: StrictHttpResponse<GetApplicationsDto>) => r.body as GetApplicationsDto)
    );
  }

  /**
   * Path part for operation apiApplicationPut
   */
  static readonly ApiApplicationPutPath = '/api/application';

  /**
   * Update application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiApplicationPut$Response(params?: {
    context?: HttpContext
    body?: UpdateAppApplicationRequest
  }
): Observable<StrictHttpResponse<GetApplicationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationService.ApiApplicationPutPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetApplicationDto>;
      })
    );
  }

  /**
   * Update application.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiApplicationPut(params?: {
    context?: HttpContext
    body?: UpdateAppApplicationRequest
  }
): Observable<GetApplicationDto> {

    return this.apiApplicationPut$Response(params).pipe(
      map((r: StrictHttpResponse<GetApplicationDto>) => r.body as GetApplicationDto)
    );
  }

  /**
   * Path part for operation apiApplicationPost
   */
  static readonly ApiApplicationPostPath = '/api/application';

  /**
   * Add application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiApplicationPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiApplicationPost$Response(params?: {
    context?: HttpContext
    body?: AddAppApplicationRequest
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationService.ApiApplicationPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Add application.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiApplicationPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiApplicationPost(params?: {
    context?: HttpContext
    body?: AddAppApplicationRequest
  }
): Observable<string> {

    return this.apiApplicationPost$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
